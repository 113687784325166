import { en } from "vuetify/lib/locale";

export default {
  dsfa_sha: "Data protec&shy;tion impact assess&shy;ment",
  farben: "Colours",
  no_logo: "No company logo present",
  aufsichtsbehoerden: "Supervisory Authorities",
  riskchart: {
  bruttorisiko: "Gross risk (excluding measures)",
  nettorisiko: "Net risk (minus measures)",
    },
  sicher_drittland: "Data is transferred to a secure third country (adequacy decision).",
  jahresbericht: "Report",
  ursacheDesVorfalls: "Cause of the incident",
  risikoanalyse_unmoeglich: "Due to your selection in the questions, no risk analysis is necessary. However, if you want to carry out a risk analysis, click on 'Continue to risk analysis'.",
  AnzahlVerarbeitungstaetigkeiten: "Number of processing activities",
  aufgabe_zeiterfassung: "Task Time recording",
  word_ansehen: "View Word template",
  bestaetigt: "Confirmed",
  format_bericht_exportieren: "  In which format should the report be exported?",
  tabbelarische_ansicht_inkludieren: " Tabular view (number) - divided by status and tasks Label",
  detail_ansicht_inkludieren: " Detailed overview (listing) - divided by status",
  uebersicht_empfaenger: " Overview External Receiver",
  unternehmen_bericht_erstellen: " For which companies should the report be prepared?",
  globale_berichte: " Global Reports",
  konfiguration_bericht: " Configuration of the report",
  uebersicht_vvt: " Overview Processing Activities",
  uebersicht_verarbeitungen_im_auftrag: " Overview of the processing on behalfs",
  uebersicht_toms: "Overview of the technical and organisational measures",
  uebersicht_loeschkonzept: " Overview of the Erasure Concept",
  uebersicht_datenschutzfolgenabschaetzungen: " Overview of the impact assessments",
  uebersicht_audits: " Overview of the audits",
  uebersicht_datenpannen: " Overview of the databreaches",
  uebersicht_ext_kom: " Overview of the external communications",
  uebersicht_auftraggeber: " Overview of the controllers",
  zeitraum_bericht: "  For which period should the report be prepared?",
  welche_bereiche: " For which areas should the report be prepared?",
  verarbeitung_eu: "Processing in the EU?",
  verarbeitung_oeffnen: "Open processing activity",
  standard_resource: "Standard Resource",
  verbindung_entfernen: "Would you like to remove this connection?",
  neuer_beteiligter: "New shareholder",
  erstellungEinerExternenKommunikation: "Creation of an external communication",
  keineMeldungengemeldet: "No data breaches have yet been reported to a supervisory authority",
  verarbeitungen: "Processing",
  wurdeDatenpanneGemeldet: "Has the data breach been or will it be reported to a supervisory authority?",
  rolle_aendern: "",
  erfolg: "Success",
  primaer: "Primary",
  resource_special_personal_data: "Resource Special category of personal data",
  warnung: "Warning",
  groeßer2: "> 2",
  loeschen_1: "Do you want to",
  wiederkehrende_pruefungen: "Periodic audits",
  vertraege: "Contracts",
  maßnahmeVertraulichkeit: "Measures to ensure confidentiality",
  vorlage_checklist: "Template Checklist",
  anzahlDerBenachrichtigtenBetroffenen: "Number of the affected data subjects notified",
  pa_9: "Select the appropriate categories of data subjects for the processing activity in accordance with Art. 30 (1) lit. c) GDPR. Note that the selection of categories has an impact on the risk assessment of the processing.",
  schließen: "Close",
  prev_question: "Previous question",
  weitere_info_risiko: "Further information on the risk calculation",
  nicht_erforderlich: "Not required",
  risikoquelleErstellen: "Create source of risk",
  info_ica: "If you are acting as a processor acc. to Art. 4 (8) GDPR within the framework of an intercompany agreement, you can assign the applicable contractual relationships by making this selection in the following step.",
  allgemeineInformationen: "General information on the processing activity",
  response_400_company_role: "",
  daten: "Data",
  kategorien_der_betroffenen: "Category(ies) of data subjects",
  einwilligungen_text: "Consent must be given explicitly for specific purposes. You can find some templates for different purposes here.",
  created_info: "Details",
  zeit_erfassen: "Recording time",
  um: "",
  wslk_verletzung: "",
  person: "Person",
  create_checklist_question_hint: "Create the question so that it can be answered with yes or no.",
  uhrzeit: "Time",
  kein_hinweis: "No hint available",
  gewichtungWaehlen: "Select a weighting between 0 and 10",
  datenpanne_info_bearbeiten: "Edit data breach reports",
  risikoszenario: "Risk scenario",
  bearbeiten: "Edit",
  geringes_risiko: "Low Risk",
  response_400_measure: "This measure has already been assigned to this company.",
  historie_loeschroutinen: "History of deletion routines",
  loeschfristen_und_loeschkonzept: "Deletion periods and deletion concept",
  interne_auftraggeber: "",
  ja: "Yes",
  total_risk: {
  netto_simple: "(Net) overall risk",
  simple: "Overall risk",
  processing_ctivity: "The processing activity is likely to have {risk}.",
  total_risk_4: "very high overall risk",
  total_risk_3: "high overall risk",
  total_risk_1: "low overall risk",
  total_risk_0: "no risk",
  total_risk_2: "medium overall risk",
  impact_assessment: "The data protection impact assessment is likely to have {risk}.",
    },
  confirmed_successfully: "",
  eingabe_ganzzahl: "The input must be an integer without separators.",
  datenpanne_info_loeschen: "Delete data breach reports",
  verarbeitungstaetigkeit_auftrag: "Processing on behalf of a controller",
  erledigt: "Done",
  secure_country: "An adequacy decision exists for the country {country}.",
  vorabeinschätzungDurchfuehrenText: "Would you like to carry out a preliminary assessment in order to obtain a recommendation as to whether a report to the supervisory authority or notification of the data subjects is necessary?",
  keine_externen_datenempfeanger: "No external data recipients",
  datenschutzverletzungen: "Data breach",
  massnahmen: "Measures",
  sehr_hohes_risiko: "Very high Risk",
  kunden: "Clients",
  resource_datenpanne_typ: "Resource Type data breach",
  bisZum: "until to",
  zunaechst_verzweigung_erstellen: "Before you can place a reference, you must first create a branch.",
  resource_dokumentenkategorie: "Resource Document category",
  nachname: "Last name",
  risikoszenarien_waehlen: "Select a risk scenario or create a new risk scenario to carry out the risk analysis in as much detail as possible",
  externe_Empfaenger: "External recipients",
  ortDesVorfalls: "Location of the incident",
  fehler_export: "Unfortunately, an error occurred during export. Please try again later.",
  lizenz_wechsel: "for changing your license",
  WE: "Weeks",
  intercompany_beauftragter: "Representative for Intercompany Agreement",
  dsfa_vorlage: "Would you like to use a template for creating the data protection impact assessment?",
  einfachauswahl: "Single Select Question",
  ReferenznummerAktenzeichenVorläufigenMeldung: "Reference number/file reference of the provisional notification",
  organisationseinstellungen: "Settings of organisation",
  moeglicheSchaedenSlider3: "high",
  critical_country: "The country {country} is not part of the European Economic Area and there is no adequacy decision.",
  zuruecksetzen: "Reset",
  benachrichtigung: "Notification",
  weitere_fragen: "Further risk questions",
  informationen_erzeugt_durch_Verarbeitungstätigkeit: "This area shows information generated by the processing of the record of processing activities.",
  resource_label: "Resource Label",
  rules: {
  name_max_length: "",
  empty_field: "This field must not be empty.",
  integer_rule: "Only integers without separators may be used",
    },
  richtlinien: "Guidelines",
  kommentar: "Comment",
  WählenSieeinJahraus: "Select a year",
  pa_3: "Select the relevant departments from the company here.",
  Info_O10: "In this processing, data of particularly sensitive persons (e.g. children, employees) are to be processed. Other organisations therefore assess the extent of damage as high and take measures to anonymise or pseudonymise data.",
  vertragsunterlagen: "Contract documents",
  drittenBeteiligteVorhanden: "",
  kleiner50: "< 50",
  receiver_type_DTL: "Service provider",
  benutzerverwaltung: "User administration",
  unterauftragnehmer: "Subcontractor",
  letzte_Aenderungen: "Latest changes",
  zurueck_dashboard: "Return to dashboard",
  measure_risk_3: "High effectiveness",
  jahre: "Years",
  uhrzeitEnde: "Time of ending",
  organisation_oeffnen: "Open organisation",
  letzteBearbeitung: "Last edit",
  ErgebnisVorMaßnahmen: "Result without measures taken",
  audit_art: "Type of the Audit",
  risiko_wahrscheinlichkeit: "Risk Propability",
  keineDrittenBeteiligten: "No third parties involved",
  begruendungFuerMeldung: "Reasons for the decision",
  response_400_error_user_exists: "",
  benutzer_bearbeiten: "Edit User",
  wahr: "True",
  WeiterZurRisikoanalyse: "Risk assessment",
  typ: "Typ",
  verantwortliche: "Controller",
  uhr: "",
  speichern_abmelden: "",
  exportieren: "Export",
  meldendePersonAuchAnsprechpartner: "Is the reporting person also the contact person?",
  risikobewertung: "Risk assessment",
  begruendungRestrisiko: "Reasoning of the residual risk",
  veroeffentlicht: "Published",
  spalteHinzufuegen: "Add column",
  artDerBenachrichtigung: "Type of the notification",
  receiver_location_secure_hint: "The recipient's registered office is in {country}. It is a third country with an adequacy decision.",
  filter_zuruecksetzen: "Reset all filters",
  erstellen: "Create",
  dokument_erstellen: "Create document",
  datenuebertragung_in_drittlaender: "Data transfer to a third country",
  schadensausmass: "Extent of damage",
  konformitaet: "Conformity in %",
  vvt_sha: "Records of pro&shy;cessing activi&shy;ties",
  receiver_type_ATV: "Processor",
  restrisiko: "Residual risk",
  contact_us: "Contact us at",
  zeitraum: "Time period",
  beteiligungDritten: "Participation of third parties",
  beschreibung_der_Verarbeitungstätigkeit: "Description of the processing activity",
  loeschfristen: "Deletion periods",
  wiederherstellen: "Restore",
  datenkategorien: "Data categories",
  create_checklist_question_info: "Notes on the creation of questions",
  checkliste: "Checklist",
  kategorien_der_empfaenger: "Category(ies) of recipients",
  auswahl_antwort: "Selection",
  beispiel: "This is a template text. Lorem Ipsum",
  wochen: "Weeks",
  auditberichtErstellen: "Create audit report",
  fehler: "Error",
  wiederholung_alle: "Repeat every",
  risiko: "Risk",
  eue_datei_hochladen: "Upload new file",
  unternehmen_erstellen: "Create company",
  eingabe_groesser_null: "Value must be greater than or equal to 0.",
  kein_pfad: "No path",
  risiko_frage: "Risk Question",
  tom_abkuerzung: "TOM",
  brutto_risk_severity: "Gross risk value of the extent of damage",
  vorlage_audit: "Template Audit",
  einheit: "Unit",
  Info_P1_11_13_14_O5: "This processing is intended to process special categories of personal data. Other organisations therefore assess the extent of damage as high and take measures to anonymise and reduce the data sets.",
  bestaetigung: "Confirmation",
  erasure_concept_hint: "You can find more information on our erasure periods and storage locations in our <a class='erasure'>erasure concept</a>.",
  risiko_ausmaß: "Risk Dimension",
  datumBeginn: "Date of beginning",
  inhaltDerBenachrichtigung: "Content of the notification",
  externe_empfaenger: "External receiver",
  auswertungen: "Evaluations",
  erstellungEinerDatenpanne: "Creation of a data breach",
  auftragnehmer: "contractor",
  bewertung: "Assessment",
  ressourcen: "Resources",
  offen: "Open",
  ergaenzendeMeldung: "Supplementary report",
  resource_receiver_category: "Resource Category recipient",
  datei_nicht_lesbar: "The file is not readable.",
  software: "Software",
  risikoquellen: "Sources of risks",
  kategorien_personenbezogener_Daten: "Category(ies) of personal data",
  resource_rechtsgrundlage: "Resource Legal basis",
  sindDritteBeteiligt: "Are third parties involved?",
  bezugderMaßnahme: "Reference to the measure",
  beteiligte_unternehmen: "Involved companies",
  word_vorlagen: "Word templates",
  offene_Aufgaben: "Open tasks",
  meldepflichtig: "Reportable",
  telefon: "Telephone",
  data_receiver_storage_ansehen: "View data recipient processing locations",
  mehr_anzeigen: "Show more",
  priority_1: "Medium",
  auftraggeber: "Customer",
  neueDatenpanne: "New data breach",
  berechtigungen: "Authorisations",
  funktionImBetroffenenUnternehmen: "Function in the company concerned",
  aufgabenuebersicht: "Overview of tasks",
  receiver_contracts_ica_prepand: "",
  appleiste_hintergrund: "Background app bar",
  hinweis_vererbte_datenempfänger_nicht_angezeigt: "Inherited data receivers are not displayed in this selection dialog if the processing activity has already been inherited by another company. This is because inherited data receiver may not be inherited.",
  AnzahlDerVerarbeitungstaetigkeiten: "Number of processing activities",
  organisation_verwalten: "Manage organisation",
  word: "Word",
  keine_orte: "No Places of processing",
  externe_kommunikation_sha: "External com&shy;munication",
  loeschenAusDerGruppe: "Delete from the group",
  keine_kategorien_der_empfaenger: "No category(ies) of recipients",
  max_upload: "Note: All selected files must not exceed 20 MB in size.",
  erstellungDerBenachrichtigung: "Creation of the notification",
  informationAndererBehoerden: "Information from other authorities",
  vvt_namen: "Please assign a name to the processing activity. This is required so that the data protection processing can be saved",
  berechtigungen_fehlen: "You do not have the necessary permissions to access this area, or access has been restricted by your organization to certain IP addresses.",
  fuegen_Sie_eine_Tom_hinzu: "Add a measure",
  allgemeine_massnahmen: "General measures",
  hinweis_benutzer_loeschen: "The user remains for 30 days, but can no longer be selected. After the 30 days have expired, the user will be permanently removed.",
  resource_loeschfrist: "Resource Deletion period",
  NeueDatenpanneErstellen: "Would you like to create a task that relates to the new data breakdown to be created?",
  audit_datareceiver: "Audit of recipient",
  strasse: "Street",
  risikoanalyse: "Risk assessment",
  verarbeitung_erstellen: "Create processing activity",
  ip_message: "You have tried to open a company with restricted access from an unauthorized IP address.",
  ausgewaehlte_dateien: "Selected files",
  vorabeinschaetzung: "Preliminary assessment",
  critical_countries: "The countries {countries} are not part of the European Economic Area and there is no adequacy decision.",
  datumDerMeldung: "Date of reporting",
  vorname: "First name",
  spalteEntfernen: "Remove column",
  kleiner70: "< 70",
  einblenden: "show",
  zeitpunkt: "Time",
  scoringwert: "Scoring value",
  response_400_resource_location: "",
  speichernUndZurAnsicht: "Save and back to overview",
  leistungsbeschreibung_hinzufuegen: "Add a service description for the subcontractor",
  startdatum: "Date of beginning",
  verabeitungenImAuftrag: "Processing for other controllers",
  benachrichtigungen: "Notifications",
  smart_audit_end: "You have successfully answered all questions. The result is displayed on the right.",
  vorlage_auswaehlen_tabelle: "Select template",
  und_tochterunternehmen: "and all subcompanies",
  pdf_bearbeiten: "Edit PDF template",
  datenpanne_report_ansehen: "View data breach notification",
  frage: "Question",
  name: "Name",
  measure_risk_1: "Low effectiveness",
  pa_17: "Select here whether data is transferred to a third country. If external recipients have been selected who have their registered office or storage locations in a third country, the third country transfer is automatically analysed.",
  betroffeneKategorienPersonenbezogenerDaten: "Categories of personal data concerned",
  typen: "Types",
  RelevanzpruefungFortsetzen: "Continue relevance check",
  report_maintenance: "The Evaluations & Reports area is currently undergoing maintenance and is therefore not available.",
  resource_externe_kommu_typ: "Resource Rype external communication",
  prioritaet: "Priority",
  bruttorisikowert: "Gross risk value",
  tom_sha: "Technical and orga&shy;nisational mea&shy;sures",
  keine_quellen: "No sources of risk selected.",
  datenschutzfolgenabschaetzung: "Data protection impact assessment",
  Bitte_bestätige_zutreffende_Fragen_durch_die_Betätigung_der_Schaltfläche_und_füge_ggf_eine_Beschreibung_hinzu: "Please confirm applicable questions by clicking the button and add a description if necessary.",
  persoenliches_Dashboard: "Personal dashboard",
  vererbung_rückgaengig: "Revert inheritances",
  dsfa_erstellen_pa: "to prepare a data protection impact assessment for this processing activity.",
  betroffenenanfragen: "Request of data subjects",
  alle_auswaehlen: "Select all",
  spezifische_angaben_zur_Verarbeitung: "Specific information about processing",
  null_wort: "0",
  data_receiver_subcontractor_erstellen: "Create data recipient subcontractor",
  ansprechpersonen: "Contact persons",
  enddatum: "Date of ending",
  risiken: "Risks",
  ansprechperson: "Contact person",
  measure_risk_0: "Ineffective",
  auswahl_hinzufuegen: "Add choice",
  darstellung_auswahl: "Presentation of the selection",
  anschrift: "Adress",
  data_receiver_storage_bearbeiten: "Edit data recipient processing locations",
  welcome_text: "Get to know the structure of the DSMS from Keyed® and continuously gain new insights into functions that have already been released. If a function is not yet available, it will be released shortly. Your personal contact at Keyed® will be happy to answer any questions you may have.",
  meldungNichtZuSpaet: "Report will be made within 72 hours of becoming known",
  verantwortlicher: "Controller",
  risk_2: "Medium",
  besondere_kategorien_personenbezogener_Daten_vorhanden: "Are special category(ies) of personal data available?",
  individuell_demo: "This area can be individually adapted to the requirements of the company (e.g. through interfaces), which is why this area is not available in the demo.",
  vererben: "Inheritance",
  meldungen: "Reportings",
  AnzahlDerDatenschutzverletzungen: "Number of data breaches",
  beschreibung_des_Vorfalls: "Description of the incident",
  bearbeitung: "Editing",
  faelligkeit: "Maturity",
  ica_upfront_confirmed_hint: "An already signed Intercompany Agreement has been uploaded, therefore the management of confirmations is not available.",
  pa_6: "Every processing activity must be legitimised by a legal basis. Select the relevant legal basis here in accordance with Art. 6 GDPR.",
  Dateien: "Data",
  gesetzlicheFragen: "Legal questions",
  confirm_intercompany_agreement: "Confirm Intercompany Agreement",
  spezifische_massnahmen: "Specific measures",
  pa_11: "Select the external recipients (data recipients, usually processors) involved in the processing activity. Note that the selection of external recipients has an influence on the risk assessment of the processing.",
  vertrag_hochladen: "Upload contract",
  keine_szenarien: "No scenarios",
  variablen: "Variables",
  zwecke_der_Verarbeitungstätigkeit: "Purposes of the processing activity",
  rolle_aktualisieren: "Update role",
  wiederkehrend: "Recurring",
  auswahl_verzweigung: "Branch selection",
  speichern: "Save",
  ideen: "Here are a few ideas:",
  dsschulung: "Data privacy training",
  auswahl_loeschen: "",
  verarbeitung_dsfa: "Open data protection impact assessment",
  rolle: "Role",
  kontaktiere_admin: "Ask the admin of your instance to grant you access.",
  frageBearbeiten: "Edit question",
  pa_14: "Evaluate the general technical and organisational measures pursuant to Art. 30 (1) lit. g) in conjunction with Art. 32 (1) GDPR to minimise the risk of the processing activity.",
  umsatz_positiv: "Turnover must be positive.",
  mittleres_risiko: "Medium Risk",
  andauernderVorfall: "Ongoing incident",
  feststellungDesVorfalls: "Determination of the incident",
  lizenz_ica: "Your license does not include the management of intercompany agreements.",
  no_labels: "No Labels chosen",
  resource_risikoquelle: "Resource Source of risk",
  ansprechpartner: "Contact persons",
  zugriff_verweigert: "Access denied",
  beschreibung_kategorien_verarbeitungen: "Description of the categories of processing operations",
  keine_massnahmen_dsfa: "No measures available.",
  uebersicht: "Overview",
  datenpannen: "Data breach",
  vorlage_verarbeitung: "Template Processing activity",
  autocomplete_suche: "Input to search ...",
  bloecke_entfernen: "Also remove all subsequent blocks",
  aufgaben_label: "Task Label",
  dashboard: "Dashboard",
  verknuepfte_aufgabe_anlegen: "Would you like to create a linked task for this documentation?",
  profilsprache: "Language of profile",
  receiver_type_SON: "Other",
  massnahmen_nicht_umgesetzt: "The following selected measures have not yet been implemented:",
  response_400_erasure_routine: "",
  nettowertBerechnen: "Calculate net risk value",
  geplant: "Planned",
  nicht_bestaetigt: "",
  wiederholungsende: "End of repetition",
  kategorienBetroffenerPersonen: "Categories of affected persons",
  exporttypWaehlen: "Select an export type",
  pa_7: "Select the appropriate categories of personal data for the processing activity in accordance with Art. 30 (1) lit. c) GDPR. Note that the selection of categories has an impact on the risk assessment of the processing.",
  DA_single: "Day",
  erfolgreich_umgesetzt: "Successfully implemented",
  vererbung: "Inheritance",
  meldendePerson: "Reporting person",
  export_vorlagen: "Export templates",
  erinnerungsfrist_fuer_Pruefungen: "Reminder period for audits",
  name_der_checkliste: "Name of the checklist",
  weiteresRisikosezenarioErfassen: "Add risk scenario",
  create_checklist_question_hint_multiselect: "Create the question in such a way that several answers can apply.",
  moeglicheSchaedenSlider2: "substantial",
  ica_officer: "Responsible user for Intercompany Agreements",
  word_erstellen: "Create Word template",
  lizenz_benutzer_hinzufuegen: "Adding additional users is not included in your license. Please contact us at",
  stammdaten: "Master data",
  NeueVVTErstellen: "Would you like to create the new processing activity based on an existing template?",
  moeglicheSchaedenSlider0: "minor",
  deutsch: "German",
  tage: "Days",
  confirmation_intercompany_agreement: "",
  benutzer_loeschen: "Remove User",
  datenschutzniveau: "Level of data protection",
  zuordnungRisikoabstufungen: "Allocation to risk classifications",
  ausgewaehlt_elemente: "Selected elements",
  bearbeitung_vererbung_blockiert: "",
  getroffeneMaßnahmen: "Measures implemented",
  datenpanne_report_loeschen: "Delete data breach notification",
  umgesetzte_maßnahmen: "Implemented measures",
  unternehmen_hinzufuegen: "Add company",
  AnzahlDerAudits: "Number of audits",
  esWurdenNochKeineMaßnahmenZurAbmilderungDesRisikosGetroffen: "No measures have yet been taken to mitigate the risk",
  dateien: "data",
  datenpanne: "Data breach",
  kategorie: "Category",
  meldungZuSpaet: "Report takes place later than 72 hours after becoming known",
  loeschfrist: "Deletion period",
  keine_dateien: "No data available",
  firma: "Company",
  upload_text_multiple: "Move files here or click here to select files.",
  tag: "Day",
  jahr: "Year",
  generator: "Document generator",
  machen_Sie_weiter: "Continue where you left",
  meldefrist: "Reporting deadline",
  EsIstEineDatenschutzfolgenabschaetzungErforderlich: "A data protection impact assessment is required.",
  nicht_speichern_wegen_standard: "This resource is a standard resource. It can therefore not be edited or changed.",
  verarbeitungs_wirklich_erstellen: "Do you really want to create a processing activity from this information?",
  zustaendig: "Responsible",
  hinweis_vererbte_verarbeitung: "",
  jahresumsatz: "Annual tunrover in euros",
  abmelden: "Logout",
  auswahlmoeglichkeiten: "Choices",
  abbrechen: "Cancel",
  tochtunternehmen_inkludieren: "Should all subsidiaries of the selected companies also be included?",
  moeglicheSchaeden: "Possible damage",
  vvt: "Records of processing activities",
  kommentare: "Comments",
  max_upload_multiple: "Note: All selected files must not exceed 20 MB in size.",
  themenkapitelBearbeiten: "Edit topic chapter",
  vererbung_an: "{name} is currently inherited to the following companies:",
  pa_2: "Select the responsible persons here who have process responsibility for this processing. Note that the first person is also automatically entered in the deletion concept for the processing.",
  unternehmen: "Company",
  kategorien_dermaßnahmen: "Category(ies) of measures",
  tom_hinweis_im_auftrag: "You can find an overview of the general technical and organisational measures in the <a class='measures'>TOM-Overview</a>.",
  zwecke: "Purposes",
  keineMoeglichenSchaeden: "No possible damage",
  datenempfaenger: "Recipient",
  zentrale_benutzerverwaltung: "Central Usermanagement",
  benutzer: "User",
  organisation_wechseln: "Change organisation",
  resource_datenpanne_grund: "Resource Reason for data breach",
  label: "Label",
  ansehen: "View",
  datumEnde: "Date of ending",
  secure_countries: "An adequacy decision exists for the countries {countries}.",
  interne_empfaenger: "Internal receivers",
  MO_single: "Month",
  risikoszenarien: "Risk scenarios",
  beteiligteEmpfaenger: "Involved recipients",
  upfront_confirmed: "Has the contract already been signed by all (future) deposited companies?",
  meine_aufgaben: "My tasks",
  meldung_vom: "Reporting from",
  besondere_personenbezogener_Daten: "Special personal data",
  einwilligungen: "Consents",
  anrede: "Title",
  jahresberichtErstellen: "Create annual report",
  create_checklist_question_hint_singleselect: "Create the question in such a way that only one answer is relevant.",
  AnzahlDerTechUndOrgMaßnahmen: "Number of technical and organisational measures",
  kopieren: "Copy",
  vererbung_bestaetigung: "Should {name} really be inherited by {companies} {sub_companies}?",
  version: "Version",
  erlaeuterung: "Explanation",
  data_receiver_warning_country: "This country is not part of the European Economic Area.",
  allgemeines: "General Information",
  du_kannst: "Click",
  vererbungen_aufheben_text: "Do you really want to remove the inheritance?",
  ica_upload_upfront_confirmed: "",
  branche: "Industry",
  hinzufuegen_themenkapitel: "Add a topic chapter",
  datenpanne_report_bearbeiten: "Edit data breach notification",
  geschaeftsfuehrer: "CEO",
  zeiterfassung: "Time recording",
  benutzer_erstellen: "Create User",
  vertrag_erstellen: "Create contract",
  resource_aufbewahrung: "Resource Storage location",
  begruendungVerspaeteteMeldung: "Reason for the delayed report",
  softwareloesungen: "Softwaresolutions",
  vollstaendiges_konzept: "Complete concept",
  email: "E-Mail",
  intercompany_agreement_delete_company: "Remove involved company",
  organisationen: "Organisations",
  nein: "No",
  vererbung_bestaetigen: "Confirm inheritance",
  risk_1: "Low",
  externe_kommunikation: "External communication",
  land: "Country",
  http_413: "Upload failed. The file size of 20 MB has been exceeded.",
  begründungFuerEntscheidung: "Reasons for the decision",
  meldung: "Report",
  ausblenden: "hide",
  demo_aufgaben_nicht_verschoben: "The tasks cannot be changed or moved in the demo.",
  resource_ort: "Resource Location",
  WählenSieeinQuartalaus: "Select a quarter",
  MeldungErfolgtSpaeter: "Is the notification made later than 72 hours after becoming known?",
  checklisten: "Checklists",
  erstellt_von: "Created by",
  inherited_receiver_included: "You cannot inherit a processing activity in which an already inherited data recipient is used.",
  erstelleMoeglicheSchaeden: "Create a possible damage",
  loeschen: "Delete",
  hinweis: "Hint",
  inhalte: "Contents",
  erstellungEinerCheckliste: "Creation of a checklist",
  englisch: "English",
  hohes_risiko: "High Risk",
  risikoszenarioErstellen: "Create risk scenario",
  farbe: "Color",
  response_400_inheritance: "This property has already been inherited to this company.",
  pa_10: "Select the internal recipients (departments) involved in the processing activity.",
  OeffneDiesesElementUmMöglicheHinweiseEinzusehen: "Open this element to view possible hints",
  dokumentationen: "Documentation",
  vererbung_erfolgreich: "Inheritance is performed asynchronously in the background. Depending on the amount of data, this may take some time. You will receive a notification as soon as the action has been completed.",
  eingangsuhrzeit: "Input time",
  AnzahlDerDatenschutzfolgenabschaetzungen: "Number of data protection impact assessments",
  werdenBetroffeneUeberDatenpanneBenachrichtigt: "Have the data subjects been or will they be notified of the data breach?",
  error_deleted_user: "A deleted user may not be selected.",
  dsb: "Data protection officer",
  ica_upload: "",
  exportiert: "",
  monat: "Month",
  fragentyp: "Question Type",
  hochgeladeneDokumente: "Uploaded documents",
  benachrichtigungBetroffenen: "Notification of data subjects",
  schulungen: "Training",
  vollstaendig_bestaetigt: "Fully confirmed",
  ergaenzendeMitteilungenAusichtsbehoerde: "Supplementary notifications to the supervisory authority",
  auditsUndChecklisten: "Audits & Checklists",
  risikoquelle: "Source of risk",
  verarbeitungsorte: "Processing locations",
  meldungAufsichtsbehoerde: "Notification to the supervisory authority",
  vorlage_dokument: "Template Document",
  response_400_processing_activity_impact_assessment: "",
  einhundertZahl: "100",
  ReferenznummerAktenzeichen: "Reference number/file reference",
  alle_Aufgaben_anzeigen: "Show all tasks",
  datumDerFeststellungDesVorfalls: "Date of the discovery of the incident",
  art: "Type",
  persoenlicheEinstellungen: "Personal settings",
  dsfa: "Data protection impact assessment",
  personenbezogene_Daten: "Personal data",
  einstellungen: "Settings",
  umgesetzt: "Implemented",
  spaltenAnzahl: "Number of columns",
  netto_risk_severity: "Net risk value of the extent of damage",
  audit_company: "Audit of company",
  diesen_kommentar: "this comment",
  stunden: "Hours",
  auditbericht_erklaerung: "The audit report summarises the results of a selected audit and serves as the basis for discussing an audit.",
  dsfa_keine_informationen: "There are no informations on the processing activity yet, please complete them.",
  farbeWaehlen: "Select colour",
  WE_single: "Week",
  grenzueberschreitendeUndAndereBenachrichtigungen: "Cross-border notifications and other notifications",
  grund: "Reason",
  logoHochladen: "Upload logo",
  webseite: "Website",
  massnahmen_frage: "Measure Question",
  heute: "Today",
  autor: "Author",
  beschreibung: "Description",
  achtung_loeschen: "ATTENTION - You remove the user from all organizations.",
  loeschen_2: "finally delete",
  informationen: "Informations",
  auswertungen_und_berichte: "Reports",
  benachrichtigung_vom: "Notification from",
  monate: "Months",
  dskoordinator: "Privacy coordinator",
  data_receiver_secure_country: "This country is not part of the European Economic Area, but there is currently an adequacy decision.",
  lizenz_vorlagen: "Your license does not include the creation of individual export templates. Contact us at ",
  WollenSieWeiterhinBenachrichtigungenErhalten: "Do you want to continue receiving notifications?",
  suchen: "Search",
  RisikobewertungOhneMaßnahmen: "Risk assessment without measures taken",
  fortfolgende_themenkapitel: "You cannot create subsequent topic chapters without asking a question within the previous topic chapter.",
  audit_vorlage_erstellen: "create audit template",
  statusgespraech: "status discussion",
  keine_massnahmen: "No measures have been selected yet.",
  loeschkonzept: "Deletion concept",
  erfolgreich_speichern: "Successfully saved",
  beschreibung_routine: "At this point, you can create a deletion routine. Note that a recurring task is created for each individual deletion period. For that purpose, select the persons who are responsible for the deletion routine for the data category {category} in the processing activity {activity}.",
  ausfuellhinweise: "Completion instructions",
  vererbt_von: "Inherited from {company}",
  verlinkung: "Linking",
  backlog: "Backlog",
  impact_assessment_recommendation: "We recommend that you carry out a data protection impact assessment for this processing activity.",
  datenpanne_info_ansehen: "View data breach reports",
  pruefungen: "Audits",
  dsfa_risiko_ergebnis: "Before measures taken, the result is {brutto}. The taken measures reduce the risk to {netto}.",
  ip_beschraenkung: "If your company has set up an IP address restriction, please contact your administrator to arrange for activation.",
  pruefungen_konfigurieren: "Configure audit",
  word_vorlage: "Word template",
  benutzereinstellungen: "User settings",
  rechtsgrundlage_der_Verarbeitungstätigkeit: "Legal basis of the processing activity",
  datenpanneMeldepflichtig: "Is the data breach reportable?",
  konformitaet_name: "Conformity",
  risikoquellenHinzufuegen: "Add source of risks",
  vorlage: "Template",
  hier: "here",
  netto_risk_probability: "Net risk value of the probability of occurrence",
  erstellungEinerTom: "Creation of a technical and organisational measure",
  dateien_hochladen: "Upload files",
  empfaenger: "Recipients",
  risikoeinschaetzung: "Risk assessment",
  dauertDerVorfallNochAn: "Is the incident still ongoing?",
  erstellungEinesAudit: "Creation of an audit",
  einstellungen_fuer_Pruefungen: "Settings for audits",
  gruppen: "",
  name_der_checklistvorlage: "Name of the checklist template",
  routine_erstellen: "Create deletion routine",
  loeschroutine: "Deletion routine",
  risikoszenarien_bearbeiten: "Edit risk scenarios",
  loeschroutine_erstellen: "Create deletion routine",
  keine_daten: "No data available",
  tom_hinweis: "Only selected technical and organisational measures for this processing activity are mentioned here. General technical and organisational measures can be found in the <a class='measures'>TOM-Overview</a>.",
  response_400_storage_location: "This processing location already exists for this data recipient.",
  export_benachrichtigung: "Download {name}",
  upload_text: "Move files here or click here to select files.",
  gewichtung: "Weighting",
  zustaendige_personen: "Responsible person",
  periode_validierung: "For a recurring task, at least one of the entries must be greater than 0.",
  oeffnen: "Open",
  keine_wiederkehrung: "No periodic audits have yet been configured for this data recipient.",
  Info_P9_10_14_15_O9: "This processing uses innovative technologies. Other organisations rate the probability of occurrence as high and take implementation measures for test systems and test cycles, as well as automatic monitoring of the technologies to contain the probability of occurrence.",
  kategorien: "Categorie(s)",
  frist: "Deadline",
  dritteBeteiligte: "Third parties involved",
  richtlinien_text: "Internal guidelines for the handling of personal data provide good orientation for employees. Templates are provided for the development of guidelines.",
  entfernen: "Delete",
  receiver_location_hint: "The registered office of the recipient is located in {country}. This country is not part of the European Economic Area and there is no adequacy decision.",
  pa_4: "Describe the processing operations in detail, taking into account the purposes of the processing activity.",
  resource_betroffene: "Resource Category data subjects",
  zutreffend: "Applicable",
  objekte: "Objects",
  pdf_vorlagen: "PDF templates",
  zustaendig_personen: "Responsible persons",
  pa_8: "Select the applicable special categories of personal data of the processing activity in accordance with Art. 30 (1) lit. c) GDPR. Note that the selection of categories has an impact on the risk assessment of the processing.",
  unternehmensstruktur: "Companystructure",
  vorfallAnEinemBestimmtenZeitpunkt: "Did the incident occur at a specific time or over a period of time?",
  vorlage_dsfa: "Template Data protection impact assessment",
  resource_status: "Ressource Status",
  hausnummer: "Number",
  alle_anzeigen: "Show all",
  question_hint_general: "First enter the question type to obtain further information on how to answer the question.",
  standard_template: "Standard Template",
  massnahmen_erstellen: "Create all measures for the company",
  passwortzuruecksetzen: "Reset password",
  datenpanne_report_erstellen: "Create data breach notification",
  filtern: "Filter",
  checkbox_ica: "",
  erfassung_von_informationen_zur_relevanzpruefung: "Collection of information for the relevance check",
  status: "Status",
  erstellungEinerDSFA: "Creation of a DPIA",
  pdf_loeschen: "Delete PDF template",
  erstmeldung: "First report",
  hinweis_tochterunternehmen_inkludiert: "",
  informationen_erstellen: "Create informations",
  Info_P6_7_8_12_O11: "This processing consists of the collection of data that can be used to assess the behaviour of natural persons. Other organisations consider the level of damage to be high and take measures to pseudonymise and encrypt the data records.",
  aufsichtInformiert: "Has the relevant supervisory authority been or will it be informed?",
  verarbeitungstaetigkeiten: "Processing activities",
  benachrichtigungenEmail: "Notifications by email",
  ausgewaehlte_datei: "Selected file",
  zunaechst_tk_erstellen: "Before you can place a question, you must first create a topic chapter.",
  benachrichtigung_meldung: "Notification & Message",
  pa_12: "Assign the respective deletion periods to each category of personal data in accordance with Art. 30 (1) lit. f) GDPR. Note that this assignment is used by the DSMS as the basis for the erasure concept. The deletion of the data categories in accordance with the deletion periods must only take place after the purpose has ceased to apply.",
  intercompany_agreement: "Intercompany Agreement",
  resource_aufsicht: "Supervisory Authority",
  sprache: "Language",
  variablenbezeichnung: "Variable name",
  kategorien_vererbung: "The categories {categories} have been inherited and cannot be removed or edited.",
  betroffene: "Data subjects",
  vertraege_einholen: "Obtain order processing contracts",
  darstellung: "View",
  audit: "Audit",
  zum_persoenlichen_dashboard: "Switch to your Personal dashboard",
  vererbung_aufheben: "Revert inheritances",
  textAbstandWaehlen: "Select text spacing",
  empfaenger_der_Pruefungen: "Receiver of audits",
  artDerMeldung: "Type of report",
  zustaendig_gruppen: "Responsible groups",
  informationen_erzeugt_durch_Verarbeitungstätigkeit_erasure: "This area displays information generated by the processing of the record of processing activities. The deletion of the data categories in accordance with the deletion periods must only take place after the purpose has ceased to apply.",
  relevanzpruefung: "Relevance check",
  vorlagen: "Templates",
  szenario: "Scenario",
  response_400_subcontractor: "The subcontractor is already stored for this data recipient.",
  netto_risk_scenario: "Net risk value of the scenario",
  bericht_erstellen: "Create report",
  begruendungUndUrsacheDerRisikoquelle: "Reason and cause of the source of risk",
  pa_16: "Use the risk to assess how sensitive the date is. The more sensitive the date, the higher the risk.",
  orte: "Locations",
  vererbung_erstellen: "Create inheritance",
  datenschutzerklaerungen: "Privacy policy",
  freitext: "Freetext - Question",
  keine_benachrichtigungen_vorhanden: "No notifications available",
  interne_Dokumente: "Internal documents",
  kopfzeilen: "Header",
  aufgabeBearbeiten: "Edit task",
  zuletzt_verwendet: "Last used",
  hinzufuegen: "add",
  measure_risk_4: "Very high effectiveness",
  receiver_type_GMV: "Jointly responsible",
  keine_vererbung: "{name} has not yet been inherited to any other company.",
  risk_3: "High",
  aufsichtsbehoerde: "Supervisory Authority",
  datumDerVorlaufigenMeldung: "Date of the provisional report",
  vertrag: "Contract",
  weitereFragen: "Further risk questions",
  keine_speicherorte: "No Places of processing stored.",
  data_receiver_subcontractor_loeschen: "Delete data recipient subcontractor",
  zur_verarbeitung: "Open processing activity",
  nameDesRisikoszenarios: "Name of the risk scenario",
  word_loeschen: "Delete Word template",
  MI: "Minutes",
  rechtsgrundlagen: "Legal basis",
  schreibe_kommentar: "Write a comment...",
  zustaendige_Person: "Responsible person",
  ende_am: "End at",
  support: "Support",
  upload_word: "Move Word-files here or click here to select Word-files.",
  versionsverlauf: "Version history",
  fuegen_sie_eine_frage_hinzu: "Add a question",
  data_receiver_storage_erstellen: "Create data recipient processing locations",
  Info_P5: "This processing consists of systematic, extensive monitoring of publicly accessible areas. Other organisations therefore rate the probability of occurrence as high and take measures against the unauthorised disclosure of this data by implementing authorisation concepts and deletion concepts.",
  tom: "Technical and organisational measures",
  frist_wiederkehrende_Pruefungen: "Deadline for recurring audits",
  nicht_umgesetzt: "Not implemented",
  vertrag_herunterladen: "Download contract",
  pfad_referenz: "This path references to",
  YE_single: "Year",
  info: "Information",
  diese_vorlage: "this Template",
  jahresbericht_erklaerung: "Our report generator provides a clear overview of data protection activities and changes over the past period. Important key figures provide valuable insights for management.",
  gruppe: "",
  bezeichnung: "Designation",
  resource_zweck: "Resource Purpose",
  frage_tk: "Question or Topic",
  erstelleDritteBeteiligte: "Create a third party",
  bewertenSieDieAusgewaehltenToms: "Evaluate the selected TOM(s)",
  betroffeneBesondereKategorienPersonenbezogenerDaten: "Special categories of personal data concerned",
  sicheres_drittland: "Safe Third Country",
  hinweis_benutzer_loeschen_plural: "The users selected for deletion remain in place for 30 days, but can no longer be selected or access any of the companies within the organizational structure. After the 30 days have expired, the users are permanently removed.",
  pdf_erstellen: "Create PDF template",
  anzahl_zu_klein: "Number too low",
  vererbungen: "Inheritances",
  funktion: "Function",
  zu_meinen_aufgaben: "Switch to my tasks",
  pa_18: "At this point, describe the categories of processing that are carried out on behalf of the company.",
  audits: "Audits",
  no_audits: "No linked audits",
  AusgewaehlteRisikoquellen: "Selected sources of risk",
  wurdenMaßnahmenZurAbmilderungGetroffen: "Have measures already been taken or will measures be taken to mitigate the risk?",
  ja_nein_frage: "Yes/No - Question",
  informationenZumVorfall: "Information about the incident",
  name_der_Verarbeitungstätigkeit: "Name of the processing activity",
  zustaendige_abteilungen: "Responsible departments",
  hintergrund: "Background",
  aendern: "Edit",
  erstellungEinerChecklisteAuswahl: "Which existing template would you like to use to create a checklist?",
  unsicher_drittland: "There is a data transfer to a third country.",
  schwereDesMoeglichenSchadens: "Severity of the potential damage",
  hinzu: "added",
  datum: "Date",
  aufgaben: "Tasks",
  verarbeitung_auftragsverarbeiter: "Processing with processors",
  anzalDerBetroffenenPersonen: "Number of persons affected",
  kein_drittland: "There is no data transfer to a third country.",
  risikowert: "Risk value",
  eingangsdatum: "Date of receipt",
  kein_zugriff: "You have no access to this company.",
  blockierte_ressource: "This object is currently being edited by {vorname} {nachname}",
  task_1: "Configure a recurring task here. You can find more information in our FAQ.",
  is_vvt: "Is processing on behalf?",
  geeignete_garantien: "Appropriate guarantees and exceptions",
  NeueDSFAErstellen: "Would you like to create the new DPIA for an existing processing activity?",
  funktion_nicht_vorhanden: "This function is not available in the demo.",
  pdf_ansehen: "View PDF template",
  pa_11_kategorien_empfaenger: "Select the categories of recipients involved in the processing activity.",
  alle_massnahmen_erstellen: "Do you really want to create all measures for the company",
  woche: "Week",
  assessmentRechtlicherHinweis: "The complexity of a data breach is minimised by the inputs. The recommendation is based on simplified assumptions and may therefore not always be accurate. The recommendation can be one of several indicators for a decision. A data breach should always be considered and assessed in detail in each individual case.",
  hinweisVonDerDigitalenRechtsassistenz: "Note from the digital legal assistant",
  vergangene_pruefungen: "Past audits",
  pdf: "PDF",
  daten_init: "",
  next_question: "Next question",
  risk_0: "No risk",
  vererbt_bekommen: "Inherited?",
  resource_massnahme: "Resource Measure",
  berichte: "Reports",
  brutto_risk_probability: "Gross risk value of the probability of occurrence",
  bearbeitungshinweis: "Editing note",
  EineRisikoanalyseIstNichtErforderlich: "A risk analysis is not necessary",
  fußzeilen: "Footer",
  vertragsstatus: "Contract status",
  ergebnis: "Result",
  keine_benachrichtigungen: "No notifications",
  auftragsverarbeitung: "Contract Processing",
  bis: "until",
  ersatzbenutzer: "Replacement user",
  keine_beschreibung: "No description available.",
  verarbeitungstaetigkeit: "Processing activitiy",
  darstellung_sonstiges: "Presentation & Other",
  keineDatenkategorienVorhanden: "No data categories have been selected yet. Select data categories under step 2 to create deletion periods afterwards.",
  auswahlToms: "Select one or more measures",
  in_bearbeitung: "In progress",
  fehler_speichern: "Error while saving",
  keine_Tags_vorhanden: "No tags available",
  stammdaten_bearbeiten: "Edit master data",
  beschreibung_der_Kenntnisse_des_Vorfalls: "Description of the knowledge of the incident",
  verzeichnisVerabeitungenImAuftrag: "Records of processing activities as a processor",
  pfad: "Path",
  csv_hochladen: "Upload csv",
  organisationsuebergreifende_Daten: "Here you can see cross-organisational data",
  abteilung: "Department",
  orte_der_verarbeitung: "Places of processing",
  datei: "Files",
  aufgaben_benachrichtigung: "You have been assigned to the task {name}",
  beschreibungGeplanteMaßnahmen: "Description of measures planned",
  priority_2: "High",
  name_des_audits: "Name of the audit",
  anzahl: "Number",
  tabelle_anzeigen: "View table",
  response_400_ica_on_behalf: "",
  datenschutzerklaerungen_text: "In order to fulfil the information obligations from Art. 12 ff. GDPR, step-by-step data protection declarations can be created here for various purposes.",
  Info_O12_14: "Data recipients are used who may be required by a third-country authority to export or disclose this data contrary to Art. 48 GDPR. Other organisations assess the probability of occurrence as high and take measures to encrypt the data to protect it from access by data recipients or authorities from third countries.",
  problem_besteht: "If the problem persists, please contact our",
  data_receiver_storage_loeschen: "Delete data recipient processing locations",
  moeglicheSchaedenSlider1: "manageable",
  mehrfachauswahl: "Multiselect Question",
  upfront_confirmed_info: "If this option is activated, the intercompany agreement is automatically confirmed for all companies that have already been added and will be added in the future. You can deactivate this option at any time so that all companies are prompted for confirmation.",
  aufbewahrungsorte: "Storage locations",
  ort_der_Verarbeitung: "Place of processing",
  keine_meldungen_vorhanden: "No reportings available",
  sonstiges: "Other",
  nettorisikowert: "Net risk value",
  bestimmtes_format: "The Word export template must have a specific format. Please load",
  filter_loeschen: "Clear all",
  erstelleMeldung: "Create a report",
  DSFABeendenUndSpeichern: "Exit and save DPIA",
  komponente_waehlen: "Choose component",
  geplante_maßnahmen: "Planned measures",
  themenkapitel: "Topic chapter",
  abteilungen: "Departments",
  datenpanne_info_erstellen: "Create data breach reports",
  neuen_vertrag_hochladen: "Upload new contract",
  anderes_konto_besitzen: "If you have another account that has additional authorizations, log in with this account.",
  welcomeToKeyed: "Welcome to Keyed",
  WählenSieEinAbgeschlossenesAuditAus: "Select a completed audit",
  AnzahlDerAufgaben: "Number of tasks",
  verarbeitung_sicheres_drittland: "Processing in a secure third country?",
  neuer_schaden: "New Damage",
  abteilung_unabhängig: "Department-independent view of objects",
  measure_risk_2: "Normal effectiveness",
  pa_1: "The name of the processing activity also serves as the title of the processing for all overviews (e.g. register of processing activities, deletion concept) within the DSMS. It is recommended that the name of the processing activity should be independent of individual IT tools that may be used in the context of processing.",
  details: "Details",
  StatusDerPruefungen: "Status of audits",
  pa_15: "The assessment of the processing activity is based on the evaluation of all the information in the previous steps. The evaluation of the risk can already be used as a guide to assess whether a data protection impact assessment (DPIA) should be carried out. Note that the processing activity can already be transferred to a DPIA to ensure efficient further processing.",
  neue_pruefung: "Create new audit",
  plz: "postcode",
  Vertraege_datenempfaenger: "Contracts for data recipients",
  benachrichtigung_erfolgt: "Notification has been sent?",
  datenuebertragung_in_drittland: "Data transfer to a third country",
  naechster_baustein: "Next Block",
  potenzielleSchaeden: "Potential damage",
  data_receiver_subcontractor_bearbeiten: "Edit data recipient subcontractor",
  HO: "Hours",
  uhrzeitDerFeststellungDesVorfalls: "Time of detection of the incident",
  themenkapitel_hinzufuegen: "Add chapter",
  ErgebnisNachMaßnahmen: "Result after measures taken",
  AnzahlDerBetroffenenanfragen: "Number of requests from data subjects",
  meldendePersonAuchAnsprechpartnerResult: "The reporting person is also the contact person",
  name_der_Datenpanne: "Name of the data breach",
  kein_risiko: "No risk",
  risk_chart_hint: "The risk is presented as a gross risk (before measures taken) and as a net risk (after measures taken). The gross risk consists of the extent of damage and the probability of occurrence of a personal data breach. The extent of the damage is calculated on the basis of the categories of personal data, the categories of data subjects and the data transfer to a third country. The information on data transfer to a third country and the deletion periods are used to calculate the probability of occurrence. All information available for selection contains risk values and weightings for influencing the risk. Finally, the gross risk is minimised by the selected technical and organisational measures (TOM) and presented as a net risk.",
  erstelldatum: "Date of creation",
  no_documents: "No linked documents",
  docs_erlaubt: "Only Word files with the file extension .docx are permitted.",
  Info_P2: "This processing is intended to process data that is subject to special confidentiality (e.g. professional secrecy). Other organisations therefore assess the extent of damage as high and take measures to anonymise, reduce and encrypt the data records.",
  add: "add",
  uhrzeitDerBenachrichtigung: "Time of the notification",
  download_edit: "and edit the template",
  bitte_auswahl: "Please make a selection first",
  benachrichtigungspflicht: "Obligation to notify",
  data_receiver_subcontractor_ansehen: "View data recipient subcontractor",
  vererbt: "Inherited ?",
  keine_risikoquellen: "No risk sources have been created yet.",
  neu: "New",
  unten: "Down",
  YE: "Years",
  personen: "Persons",
  geplanteMaßnahmen: "Planned measures",
  InformationenBearbeiten: "Edit informations",
  auswahltext: "Selection text",
  priority_0: "Low",
  dsfa_erstellen: "Create data protection impact assessment",
  MO: "Months",
  ort: "Location",
  bearbeiten_klein: "edit",
  zurueck: "Back",
  erstellungEinerVearbeitungstaetigkeit: "Creation of a processing activity",
  lizenz_unzureichend: "This function is not included in your license. Please contact us at",
  risikoquellen_waehlen: "Select one or more sources of risk and explain the causes and potential damage in each case.",
  zwecke_der_Verarbeitung: "Purposes of the processing",
  oben: "Top",
  dokumente: "Documents",
  create_checklist_question_hint_freetext: "Create the question in such a way that the answer can be formulated individually.",
  weitereMitteilungenAnDatenschutzaufsichtsbehoerde: "Further notifications to the data protection supervisory authority",
  eintrittswahrscheinlichkeit: "Probability of occurrence",
  receiver_contracts_ica_append: "",
  diese_stunden: "these hours",
  herunterladen: "Download",
  name_der_auditvorlage: "Name of the audit template",
  conformity_result: "The conformity of the entire audit amounts to",
  rollen: "Roles",
  uhrzeitDermeldung: "Time of reporting",
  weiter: "Next",
  BearbeitungDerBenötigtenInformationen: "Editing of the required information",
  Bitte_bestätige_zutreffende_einschlägige_Fragen_durch_die_Betätigung_der_Schaltfläche_und_füge_ggf_eine_Beschreibung_hinzu: "Please confirm applicable relevant questions by clicking the button and add a description if necessary.",
  beschreibungGetroffeneMaßnahmen: "Description of measures implemented",
  pa_5: "Select the relevant purposes for the processing activity pursuant to Art. 30 (1) lit. b) GDPR.",
  verarbeitungsvorgaenge_beschreiben: "Describe the processing operations in detail here, taking into account the purposes of the processing activity.",
  auditbericht: "Auditreport",
  verknuepfte_Dokumentation: "Linked documentation",
  erstellen_gross: "Create",
  intercompany_agreement_add_company: "Add company involved",
  pa_13: "Take specific technical and organisational measures pursuant to Art. 30 (1) lit. g) in conjunction with Art. 32 (1) GDPR to minimise the risk of the processing activity.",
  spaltenHoehe: "Column height",
  ZusammenfassungDerRisiken: "Summary of risks",
  neue_aufgabe: "New task",
  massnahme: "Measure",
  ausmass_verletzung: "",
  tochter_erstellen: "Create subsidiary company",
  DA: "Days",
  stundenanzahl: "Number of hours",
  kleiner2: "< 2",
  resource_personal_data: "Resource Category of personal data",
  hochladen: "upload",
  vorlage_auswaehlen: "Select template",
  aufgabe: "Task",
  datenkategorie: "Data category",
  artDesVorfalls: "Type of the incident",
  datumDerBenachrichtigung: "Date of notification",
  geprueft: "Checked",
  uhrzeitBeginn: "Time of beginning",
  ersteller: "Creator",
  falsch: "False",
  datei_herunterladen: "Download file",
  Vertraege_datenempfaenger_text: "An appropriate level of protection must be contractually guaranteed, especially when transferring data. Templates for these contracts are offered here for editing in the usual market constellations.",
  themenkapitel_frage_auswaehlen: "Choose chapter or question",
  erstellt_am: "Created at",
  receiver_type_ICA: "Intercompany Agreement",
  NeueExterneKommunikationErstellen: "Would you like to create a task that relates to the new external communication to be created?",
  erstellungEinesAuditAuswahl: "Which existing template would you like to use to create an audit?",
  hinzufuegen_themenkapitel_oder_frage: "Add a topic chapter or a question",
  smartes_audit: "Should the template be created for a smart audit?",
  leistungsbeschreibung_aendern: "Change the service description for the subcontractor",
  datei_hochladen: "Upload file",
  type_communication: "Types of external communication",
  block_entfernen: "Would you really like to remove this block?",
  anzalDerBetroffenenDatensaetze: "Number of data records affected",
  risk_4: "Very high",
  besondere_kategorien_personenbezogener_Daten: "Special category(ies) of personal data",
  antwort: "Answer",
  risikoaudit: "Risk audit",
  konformitaetsaudit: "Compliance audit",
  adressat: "Target",
  wert: "Value",
  http_async_inherit: "Inheritance is executed asynchronously in the background. Depending on the amount of data, this may take some time. You will receive a notification as soon as the action has been successfully completed.",
  http_async_process: "Processing is carried out asynchronously in the background. Depending on the volume of data, this may take some time. You will receive a notification as soon as the action has been completed.",
  http_async_destroy: "The deletion is carried out asynchronously in the background. Depending on the volume of data, this may take some time. You will receive a notification as soon as the action has been completed.",
  error_background_task_object_blocked: "The data record could not be updated because a background process has not yet been completed.",
  error_background_task_object_blocked_destroy: "The data record could not be deleted because a background process has not yet been completed.",
  done_background_task_inheritance: "The inheritance from {name} to {count} company is complete",
  done_background_task: "Update for {name} completed in the background",
  http_async_process_revert_inherit: "Inheritance is canceled asynchronously in the background. Depending on the amount of data, this may take some time. You will receive a notification as soon as the action has been successfully completed.",
  leereAppAusgewaehlt: "No type of information output has been defined. Please select at least the list overview or, when selecting the detail overview, an appropriate status. The following applications are affected: ",
  keineAppAusgewaehlt: "No application has been selected for the information output. Please select at least one application.",
  diesesJahr: "This year",
  letztesJahr: "Last year",
  voreinstellungen: "Presets",
  uebersicht_dsfa: "Overview DSFA",
  datum_waehlen: "Please choose a date",
  detail_ansicht_inkludieren_audit: "Detailed overview (listing) - divided by type of audit",
  detail_ansicht_inkludieren_deaktiviert: "Detailed overview (listing) is not possible for this application",
  verzweigung: "Branch",
  keineAntwort: "No answer",
  smartes_audit_speichern: "Editing must be fully completed before you can save the smart audit.",
  allgemein: "General",
  beginnDerAufzeichnung: "Start of the recording",
  eingabe_kleiner_gleich: "The value must be less than or equal to {value}",
  fehler_export_bericht: "The export could not be generated",
  $vuetify: {
    ...en,
  },
};
