import { de } from "vuetify/lib/locale";

export default {
  dsfa_sha: "Datenschutz&shy;folgen&shy;abschätzung",
  farben: "Farben",
  no_logo: "Kein Logo vorhanden",
  aufsichtsbehoerden: "Aufsichtsbehörden",
  riskchart: {
  bruttorisiko: "Bruttorisiko (ohne Maßnahmen)",
  nettorisiko: "Nettorisiko (abzgl. Maßnahmen)",
    },
  sicher_drittland: "Es erfolgt eine Datenübermittlung in ein sicheres Drittland (Angemessenheitsbeschluss).",
  jahresbericht: "Bericht",
  ursacheDesVorfalls: "Ursache des Vorfalls",
  risikoanalyse_unmoeglich: "Durch Ihre Auswahl bei den Fragen ist keine Risikoanalyse notwendig. Wenn Sie jedoch eine Risikoanalyse durchführen wollen drücken Sie auf 'Weiter zur Risikoanalyse'.",
  AnzahlVerarbeitungstaetigkeiten: "Anzahl Verarbeitungstätigkeiten",
  aufgabe_zeiterfassung: "Aufgabe Zeiterfassung",
  word_ansehen: "Word Vorlage ansehen",
  bestaetigt: "Bestätigt",
  format_bericht_exportieren: " In welchem Format soll der Bericht exportiert werden?",
  tabbelarische_ansicht_inkludieren: " Tabbelarische Ansicht (Anzahl) - aufgeteilt nach Status und Aufgaben Label",
  detail_ansicht_inkludieren: " Detailübersicht (Auflistung) - aufgeteilt nach Status",
  uebersicht_empfaenger: " Übersicht der Datenempfänger",
  unternehmen_bericht_erstellen: " Für welche Unternehmen soll der Bericht erstellt werden?",
  globale_berichte: " Globale Berichte",
  konfiguration_bericht: " Konfiguration Bericht",
  uebersicht_vvt: " Übersicht der Verarbeitungstätigkeiten",
  uebersicht_verarbeitungen_im_auftrag: " Übersicht der Verarbeitungen im Auftrag",
  uebersicht_toms: "Übersicht der TOM's",
  uebersicht_loeschkonzept: " Übersicht Löschkonzept",
  uebersicht_datenschutzfolgenabschaetzungen: " Übersicht Datenschutzfolgenabschätzungen",
  uebersicht_audits: " Übersicht der Audits",
  uebersicht_datenpannen: " Übersicht der Datenpannen",
  uebersicht_ext_kom: " Übersicht der externen Kommunikation",
  uebersicht_auftraggeber: " Übersicht der Auftraggeber",
  zeitraum_bericht: " Für welchen Zeitraum soll der Bericht erstellt werden?",
  welche_bereiche: " Für welche Bereiche soll der Bericht erstellt werden?",
  verarbeitung_eu: "Verarbeitung in der EU?",
  verarbeitung_oeffnen: "Verarbeitungstätigkeit öffnen",
  standard_resource: "Standardressource",
  verbindung_entfernen: "Möchtest du diese Verbindung entfernen?",
  neuer_beteiligter: "Neuer Beteiligter",
  erstellungEinerExternenKommunikation: "Erstellung einer Externen Kommunikation",
  keineMeldungengemeldet: "Es wurde noch keine Datenpannen an eine Aufsichtsbehörde gemeldet",
  verarbeitungen: "Verarbeitungen",
  wurdeDatenpanneGemeldet: "Wurde oder wird die Datenpanne an eine Aufsichtsbehörde gemeldet?",
  rolle_aendern: "Rolle ändern",
  erfolg: "Erfolg",
  primaer: "Primär",
  resource_special_personal_data: "Ressource Besondere Kategorie personenbezogener Daten",
  warnung: "Warnung",
  groeßer2: "> 2",
  loeschen_1: "Möchten Sie",
  wiederkehrende_pruefungen: "Wiederkehrende Prüfungen",
  vertraege: "Verträge",
  maßnahmeVertraulichkeit: "Maßnahmen zur Gewährleistung der Vertraulichkeit",
  vorlage_checklist: "Vorlage Checkliste",
  anzahlDerBenachrichtigtenBetroffenen: "Anzahl der benachrichtigten Betroffenen",
  pa_9: "Wählen Sie die zutreffenden Kategorien der betroffenen Personengruppen der Verarbeitungstätigkeit aus gem. Art. 30 Abs. 1 lit. c) DSGVO . Beachten Sie, dass die Auswahl der Kategorien einen Einfluss auf die Risikobewertung der Verarbeitung haben.",
  schließen: "Schließen",
  prev_question: "Vorherige Frage",
  weitere_info_risiko: "Weitere Hinweise zur Risikoberechnung",
  nicht_erforderlich: "Nicht erforderlich",
  risikoquelleErstellen: "Risikoquelle Erstellen",
  info_ica: "Sofern Sie als Auftragsverarbeiter i.S.d. Art. 4 Nr. 8 DSGVO im Rahmen eines Intercompany Agreement tätig sind, können Sie durch diese Auswahl im folgenden Schritt die zutreffenden Vertragsbeziehungen zuordnen.",
  allgemeineInformationen: "Allgemeine Informationen zu der Verarbeitungstätigkeit",
  response_400_company_role: "Diese Rolle ist als Standardrolle für das Unternehmen definiert und kann daher nicht entfernt werden.",
  daten: "Daten",
  kategorien_der_betroffenen: "Kategorie(n) der Betroffenen",
  einwilligungen_text: "Einwilligungen müssen explizit für konkrete Zwecke erteilt werden. Hier werden verschiedene Vorlagen für unterschiedliche Zwecke bereitgestellt.",
  created_info: "Details",
  zeit_erfassen: "Zeit erfassen",
  um: "um",
  wslk_verletzung: "Die Eintrittswahrscheinlichkeit einer Verletzung des Schutzes personenbezogener Daten wird vornemlich beeinflusst durch die Auswahl der Risikoquellen.",
  person: "Person",
  create_checklist_question_hint: "Erstellen Sie die Frage so, dass diese mit Ja oder Nein beantwortet werden kann",
  uhrzeit: "Uhrzeit",
  kein_hinweis: "Kein Hinweis vorhanden",
  gewichtungWaehlen: "Wählen Sie eine Gewichtung zwischen 0 und 10",
  datenpanne_info_bearbeiten: "Datenpanne Meldungen bearbeiten",
  risikoszenario: "Risikoszenario",
  bearbeiten: "Bearbeiten",
  geringes_risiko: "Geringes Risiko",
  response_400_measure: "Diese Maßnahme ist dem Unternehmen bereits zugeordnet.",
  historie_loeschroutinen: "Historie Löschroutinen",
  loeschfristen_und_loeschkonzept: "Löschfristen und Löschkonzept",
  interne_auftraggeber: "Interne Auftraggeber",
  ja: "Ja",
  total_risk: {
  netto_simple: "(Netto) Gesamtrisiko",
  simple: "Gesamtrisiko",
  processing_ctivity: "Die Verarbeitungstätigkeit hat voraussichtlich {risk}.",
  total_risk_4: "ein sehr hohes Gesamtrisiko",
  total_risk_3: "ein hohes Gesamtrisiko",
  total_risk_1: "ein geringes Gesamtrisiko",
  total_risk_0: "kein Risiko",
  total_risk_2: "ein mittleres Gesamtrisiko",
  impact_assessment: "Die Dateschutzfolgeabschätzung hat voraussichtlich {risk}.",
    },
  confirmed_successfully: "Erfolgreich bestätigt",
  eingabe_ganzzahl: "Die Eingabe muss eine Ganzzahl ohne Trennzeichen sein.",
  datenpanne_info_loeschen: "Datenpanne Meldungen löschen",
  verarbeitungstaetigkeit_auftrag: "Verarbeitung im Auftrag",
  erledigt: "Erledigt",
  secure_country: "Für das Land {country} besteht ein Angemessenheitsbeschluss.",
  vorabeinschätzungDurchfuehrenText: "Möchten Sie eine Vorabeinschätzung durchführen, um eine Empfehlung zu erhalten, ob eine Meldung an die Aufsichtsbehörde oder die Benachrichtigung der Betroffenen notwendig ist?",
  keine_externen_datenempfeanger: "Keine externen Datenempfänger",
  datenschutzverletzungen: "Datenschutzverletzungen",
  massnahmen: "Maßnahmen",
  sehr_hohes_risiko: "Sehr hohes Risiko",
  kunden: "Klienten",
  resource_datenpanne_typ: "Ressource Typ Datenpanne",
  bisZum: "bis zum",
  zunaechst_verzweigung_erstellen: "Bevor Sie eine Referenz platzieren können, muss zunächst eine Verzweigung erstellt werden.",
  resource_dokumentenkategorie: "Ressource Dokumentenkategorie",
  nachname: "Nachname",
  risikoszenarien_waehlen: "Wähle ein Risikoszenario aus oder erstelle ein neues Risikoszenario, um die Risikoanalyse möglichst detailreich durchzuführen.",
  externe_Empfaenger: "Externe Empfänger",
  ortDesVorfalls: "Ort des Vorfalls",
  fehler_export: "Beim Export ist leider ein Fehler aufgetreten. Versuchen Sie es später bitte erneut",
  lizenz_wechsel: "für den Wechsel der Lizenz",
  WE: "Wochen",
  intercompany_beauftragter: "Beauftragter für Intercompany Agreement",
  dsfa_vorlage: "Möchten Sie eine Vorlage für die Erstellung der Datenschutzfolgenabschätzung verwenden?",
  einfachauswahl: "Einfachauswahl - Frage",
  ReferenznummerAktenzeichenVorläufigenMeldung: "Referenznummer/Aktenzeichen der vorläufigen Meldung",
  organisationseinstellungen: "Organisationseinstellungen",
  moeglicheSchaedenSlider3: "groß",
  critical_country: "Das Land {country} ist nicht Teil des europäischen Wirtschaftsraums und es besteht kein Angemessenheitsbeschluss.",
  zuruecksetzen: "Zurücksetzen",
  benachrichtigung: "Benachrichtigung",
  weitere_fragen: "Weitere Risikofragen",
  informationen_erzeugt_durch_Verarbeitungstätigkeit: "Dieser Bereich zeigt Informationen an, welche durch die Bearbeitung von dem Verzeichnis von Verarbeitungstätigkeiten erzeugt werden.",
  resource_label: "Ressourcen-Label",
  rules: {
  name_max_length: "Die Eingabe darf maximal 255 Zeichen lang sein.",
  empty_field: "Dieses Feld darf nicht leer sein.",
  integer_rule: "Es dürfen nur Ganzzahlen ohne Trennzeichen verwendet werden",
    },
  richtlinien: "Richtlinien",
  kommentar: "Kommentar",
  WählenSieeinJahraus: "Wählen Sie ein Jahr aus",
  pa_3: "Wählen Sie hier die zuständigen Abteilungen aus dem Unternehmen aus.",
  Info_O10: "Bei dieser Verarbeitung sollen Daten von besonders schützenswerten Personen (z.B. Kinder, Beschäftigte) verarbeitet werden. Andere Organisationen bewerten daher das Schadensausmaß als hoch und treffen Maßnahmen zur Anonymisierung oder Pseudonymisierung.",
  vertragsunterlagen: "Vertragsunterlagen",
  drittenBeteiligteVorhanden: "Es sind dritte beteiligt",
  kleiner50: "< 50",
  receiver_type_DTL: "Dienstleister",
  benutzerverwaltung: "Benutzerverwaltung",
  unterauftragnehmer: "Unterauftragnehmer",
  letzte_Aenderungen: "Letzte Änderungen",
  zurueck_dashboard: "Zurück zum Dashboard",
  measure_risk_3: "Hohe Wirksamkeit",
  jahre: "Jahre",
  uhrzeitEnde: "Uhrzeit Ende",
  organisation_oeffnen: "Organisation öffnen",
  letzteBearbeitung: "Letzte Bearbeitung",
  ErgebnisVorMaßnahmen: "Ergebnis vor Maßnahmen",
  audit_art: "Art des Audits",
  risiko_wahrscheinlichkeit: "Risiko Wahrscheinlichkeit",
  keineDrittenBeteiligten: "Keine dritten Beteiligten",
  begruendungFuerMeldung: "Begründung für die Entscheidung",
  response_400_error_user_exists: "Ein Benutzer mit dieser E-Mail-Adresse existiert in diesem Unternehmen bereits.",
  benutzer_bearbeiten: "Benutzer bearbeiten",
  wahr: "Wahr",
  WeiterZurRisikoanalyse: "Weiter zur Risikoanalyse",
  typ: "Typ",
  verantwortliche: "Verantwortliche",
  uhr: "Uhr",
  speichern_abmelden: "Speichern und abmelden",
  exportieren: "Exportieren",
  meldendePersonAuchAnsprechpartner: "Ist die meldende Person auch die Ansprechperson?",
  risikobewertung: "Risikobewertung",
  begruendungRestrisiko: "Begründung des Restrisikos",
  veroeffentlicht: "Veröffentlicht",
  spalteHinzufuegen: "Spalte hinzufügen",
  artDerBenachrichtigung: "Art der Benachrichtigung",
  receiver_location_secure_hint: "Der Sitz des Empfängers liegt im Land {country}. Es handelt sich um ein Drittland mit einem Angemessenheitsbeschluss.",
  filter_zuruecksetzen: "Filter zurücksetzen",
  erstellen: "erstellen",
  dokument_erstellen: "Dokument erstellen",
  datenuebertragung_in_drittlaender: "Datenübertragung in ein Drittland",
  schadensausmass: "Schadensausmaß",
  konformitaet: "Konformität in %",
  vvt_sha: "Verzeichnis von Verarbeitungs&shy;tätigkeiten",
  receiver_type_ATV: "Auftragsverarbeiter",
  restrisiko: "Restrisiko",
  contact_us: "Kontaktieren Sie uns unter",
  zeitraum: "Zeitraum",
  beteiligungDritten: "Beteiligung von Dritten",
  beschreibung_der_Verarbeitungstätigkeit: "Beschreibung der Verarbeitungstätigkeit",
  loeschfristen: "Löschfristen",
  wiederherstellen: "Wiederherstellen",
  datenkategorien: "Datenkategorien",
  create_checklist_question_info: "Hinweise zur Erstellung von Fragen",
  checkliste: "Checkliste",
  kategorien_der_empfaenger: "Kategorie(n) der Empfänger",
  auswahl_antwort: "Ausgewählte Antwort",
  beispiel: "Das ist ein Beispieltext. Lorem Ipsum",
  wochen: "Wochen",
  auditberichtErstellen: "Auditbericht erstellen",
  fehler: "Fehler",
  wiederholung_alle: "Wiederholung alle",
  risiko: "Risiko",
  eue_datei_hochladen: "Neue Datei hochladen",
  unternehmen_erstellen: "Unternehmen erstellen",
  eingabe_groesser_null: "Der Wert muss größer oder gleich 0 sein.",
  kein_pfad: "Kein Pfad",
  risiko_frage: "Risiko-Frage",
  tom_abkuerzung: "TOM",
  brutto_risk_severity: "Bruttorisikowert des Schadensausmaßes",
  vorlage_audit: "Vorlage Audit",
  einheit: "Einheit",
  Info_P1_11_13_14_O5: "Bei dieser Verarbeitung sollen besondere Kategorien von personenbezogenen Daten verarbeitet werden. Andere Organisationen bewerten daher das Schadensausmaß als hoch und treffen Maßnahmen zur Anonymisierung und Reduzierung der Datensätze.",
  bestaetigung: "Bestätigung",
  erasure_concept_hint: "In unserem <a class='erasure'>Löschkonzept</a> finden Sie weitere Informationen zu unseren Löschfristen und Aufbewahrungsorten.",
  risiko_ausmaß: "Risiko Ausmaß",
  datumBeginn: "Datum Beginn",
  inhaltDerBenachrichtigung: "Inhalt der Benachrichtigung",
  externe_empfaenger: "Externe Empfänger",
  auswertungen: "Auswertungen",
  erstellungEinerDatenpanne: "Erstellung einer Datenpanne",
  auftragnehmer: "Auftragnehmer",
  bewertung: "Bewertung",
  ressourcen: "Ressourcen",
  offen: "Offen",
  ergaenzendeMeldung: "Ergänzende Meldung",
  resource_receiver_category: "Ressource Kategorie Empfänger",
  datei_nicht_lesbar: "Die Datei kann nicht gelesen werden.",
  software: "Software",
  risikoquellen: "Risikoquellen",
  kategorien_personenbezogener_Daten: "Kategorie(n) personenbezogener Daten",
  resource_rechtsgrundlage: "Ressource Rechtsgrundlage",
  sindDritteBeteiligt: "Sind Dritte beteiligt?",
  bezugderMaßnahme: "Bezug der Maßnahme",
  beteiligte_unternehmen: "Beteiligte Unternehmen",
  word_vorlagen: "Word Vorlagen",
  offene_Aufgaben: "Offene Aufgaben",
  meldepflichtig: "Meldepflichtig",
  telefon: "Telefon",
  data_receiver_storage_ansehen: "Datenempfänger Verarbeitungsorte ansehen",
  mehr_anzeigen: "Mehr anzeigen",
  priority_1: "Mittel",
  auftraggeber: "Auftraggeber",
  neueDatenpanne: "Neue Datenpanne",
  berechtigungen: "Berechtigungen",
  funktionImBetroffenenUnternehmen: "Funktion im betroffenen Unternehmen",
  aufgabenuebersicht: "Aufgabenübersicht",
  receiver_contracts_ica_prepand: "Dieser Datenempfänger wurde über ein Intercompany Agreement erzeugt, daher können keine separaten Verträge hinterlegt werden. Sie können die Verträge im ",
  appleiste_hintergrund: "Hintergrund App-Leiste",
  hinweis_vererbte_datenempfänger_nicht_angezeigt: "Vererbte Datenempfänger werden in diesem Auswahldialog nicht angezeigt, wenn die Verarbeitungstätigkeit bereits an ein anderes Unternehmen vererbt wurde. Dies liegt daran, dass vererbte Datenempfänger nicht weitervererbt werden dürfen.",
  AnzahlDerVerarbeitungstaetigkeiten: "Anzahl der Verarbeitungstätigkeiten",
  organisation_verwalten: "Organisation verwalten",
  word: "Word",
  keine_orte: "Keine Orte der Verarbeitung",
  externe_kommunikation_sha: "Externe Kom&shy;muni&shy;kation",
  loeschenAusDerGruppe: "Löschen aus der Gruppe",
  keine_kategorien_der_empfaenger: "Keine Kategorie(n) der Empfänger",
  max_upload: "Hinweis: Die ausgewählte Datei darf maximal 20 MB groß sein.",
  erstellungDerBenachrichtigung: "Erstellung der Benachrichtigung",
  informationAndererBehoerden: "Information anderer Behörden",
  vvt_namen: "Bitte vergeben sie der Verarbeitungstätigkeit einen Namen. Dieser wird benötigt, damit die Datenschutzverarbeitung gespeichert werden kann",
  berechtigungen_fehlen: "Ihnen fehlen die erforderlichen Berechtigungen, um diesen Bereich aufzurufen, oder der Zugriff wurde von Ihrer Organisation auf bestimmte IP-Adressen begrenzt.",
  fuegen_Sie_eine_Tom_hinzu: "Fügen Sie eine TOM hinzu",
  allgemeine_massnahmen: "Allgemeine Maßnahmen",
  hinweis_benutzer_loeschen: "Der Benutzer bleibt 30 Tage weiter bestehen, kann jedoch nicht mehr ausgewählt werden. Nach Ablauf der 30 Tage wird der Benutzer endgültig entfernt.",
  resource_loeschfrist: "Ressource Löschfrist",
  NeueDatenpanneErstellen: "Möchten Sie eine Aufgabe erstellen, die sich auf die neu zu erstellende Datenpanne bezieht?",
  audit_datareceiver: "Audit Datenempfänger",
  strasse: "Straße",
  risikoanalyse: "Risikoanalyse",
  verarbeitung_erstellen: "Verarbeitungstätigkeit erstellen",
  ip_message: "Sie haben versucht, von einer nicht autorisierten IP-Adresse ein Unternehmen mit beschränktem Zugriff zu öffnen.",
  ausgewaehlte_dateien: "Ausgewählte Dateien",
  vorabeinschaetzung: "Vorabeinschätzung",
  critical_countries: "Die Länder {countries} sind nicht Teil des europäischen Wirtschaftsraums und es besteht kein Angemessenheitsbeschluss.",
  datumDerMeldung: "Datum der Meldung",
  vorname: "Vorname",
  spalteEntfernen: "Spalte entfernen",
  kleiner70: "< 70",
  einblenden: "einblenden",
  zeitpunkt: "Zeitpunkt",
  scoringwert: "Scoringwert",
  response_400_resource_location: "Es existiert bereits ein Ort mit diesen Daten.",
  speichernUndZurAnsicht: "Speichern & zur Ansicht",
  leistungsbeschreibung_hinzufuegen: "Füge eine Leistungsbeschreibung für den Subunternehmer",
  startdatum: "Startdatum",
  verabeitungenImAuftrag: "Verarbeitungen im Auftrag",
  benachrichtigungen: "Benachrichtigungen",
  smart_audit_end: "Sie haben alle Fragen erfolgreich beantwortet. Rechts wird Ihnen das Ergebnis angezeigt.",
  vorlage_auswaehlen_tabelle: "Wählen Sie eine Vorlage aus",
  und_tochterunternehmen: "und alle Tochterunternehmen",
  pdf_bearbeiten: "PDF Vorlage bearbeiten",
  datenpanne_report_ansehen: "Datenpanne Benachrichtigung ansehen",
  frage: "Frage",
  name: "Name",
  measure_risk_1: "Geringe Wirksamkeit",
  pa_17: "Wählen Sie an dieser Stelle aus, ob eine Datenübertragung in ein Drittland stattfindet. Wenn externe Empfänger ausgewählt wurden, welche ihren Sitz oder Speicherorte in einem Drittland haben, wird die Drittlandübermittlung automatisiert ausgewertet.",
  betroffeneKategorienPersonenbezogenerDaten: "Betroffene Kategorien personenbezogener Daten",
  typen: "Typen",
  RelevanzpruefungFortsetzen: "Relevanzprüfung Fortsetzen",
  report_maintenance: "Der Bereich Auswertungen & Berichte wird aktuell gewartet und steht daher nicht zur Verfügung.",
  resource_externe_kommu_typ: "Ressource Typ Externe Kommunikation",
  prioritaet: "Priorität",
  bruttorisikowert: "Bruttorisikowert",
  tom_sha: "Technische und organi&shy;satorische Maß&shy;nahmen",
  keine_quellen: "Keine Risikoquellen ausgewählt.",
  datenschutzfolgenabschaetzung: "Datenschutzfolgenabschätzung",
  Bitte_bestätige_zutreffende_Fragen_durch_die_Betätigung_der_Schaltfläche_und_füge_ggf_eine_Beschreibung_hinzu: "Bitte bestätigen Sie zutreffende Fragen durch die Betätigung der Schaltfläche und fügen Sie ggf. eine Beschreibung hinzu.",
  persoenliches_Dashboard: "Persönliches Dashboard",
  vererbung_rückgaengig: "Vererbungen rückgängig machen",
  dsfa_erstellen_pa: "eine Datenschutzfolgenabschätzung zu dieser Verarbeitungstätigkeit erstellen.",
  betroffenenanfragen: "Betroffenenanfragen",
  alle_auswaehlen: "Alle auswählen",
  spezifische_angaben_zur_Verarbeitung: "Spezifische Angaben zur Verarbeitung",
  null_wort: "0",
  data_receiver_subcontractor_erstellen: "Datenempfänger Unterauftragnehmer erstellen",
  ansprechpersonen: "Ansprechpersonen",
  enddatum: "Enddatum",
  risiken: "Risiken",
  ansprechperson: "Ansprechperson",
  measure_risk_0: "Unwirksam",
  auswahl_hinzufuegen: "Auswahl hinzufügen",
  darstellung_auswahl: "Darstellung der Auswahl",
  anschrift: "Anschrift",
  data_receiver_storage_bearbeiten: "Datenempfänger Verarbeitungsorte bearbeiten",
  welcome_text: "Lernen Sie den Aufbau des DSMS von Keyed® kennen und erhalten Sie fortlaufend neue Einblicke in bereits veröffentlichte Funktionen. Sollte eine Funktion noch nicht verfügbar sein, wird diese in Kürze veröffentlicht. Für Fragen steht dir dein persönlicher Ansprechpartner von Keyed® gerne zur Verfügung.",
  meldungNichtZuSpaet: "Meldung erfolgt innerhalb von 72 Stunden nach Bekanntwerden",
  verantwortlicher: "Verantwortlicher",
  risk_2: "Mittel",
  besondere_kategorien_personenbezogener_Daten_vorhanden: "Sind Besondere Kategorie(n) personenbezogener Daten vorhanden?",
  individuell_demo: "Dieser Bereich kann individuell auf die Anforderungen des Unternehmens (z.B. durch Schnittstellen) angepasst werden, daher ist dieser Bereich in der Demo nicht verfügbar.",
  vererben: "Vererben",
  meldungen: "Meldungen",
  AnzahlDerDatenschutzverletzungen: "Anzahl der Datenschutzverletzungen",
  beschreibung_des_Vorfalls: "Beschreibung des Vorfalls",
  bearbeitung: "Bearbeitung",
  faelligkeit: "Fälligkeit",
  ica_upfront_confirmed_hint: "Es handelt sich um ein bereits unterzeichnetes Intercompany Agreement, daher ist die Verwaltung der Bestätigungen nicht verfügbar.",
  pa_6: "Jede Verarbeitungstätigkeit muss durch eine Rechtsgrundlage legitimiert werden. Wählen Sie hier die einschlägige Rechtsgrundlage gem. Art. 6 DSGVO aus.",
  Dateien: "Dateien",
  gesetzlicheFragen: "Gesetzliche Fragen",
  confirm_intercompany_agreement: "Intercompany Agreement bestätigen",
  spezifische_massnahmen: "Spezifische Maßnahmen",
  pa_11: "Wählen Sie die beteiligten externen Empfänger (Datenempfänger, i.d.R. Auftragsverarbeiter) der Verarbeitungstätigkeit aus. Beachten Sie, dass die Auswahl der externen Empfänger einen Einfluss auf die Risikobewertung der Verarbeitung haben.",
  vertrag_hochladen: "Vertrag hochladen",
  keine_szenarien: "Keine Szenarien",
  variablen: "Variablen",
  zwecke_der_Verarbeitungstätigkeit: "Zwecke der Verarbeitungstätigkeit",
  rolle_aktualisieren: "Rolle aktualisieren",
  wiederkehrend: "Wiederkehrend",
  auswahl_verzweigung: "Auswahl Verzweigung",
  speichern: "Speichern",
  ideen: "Hier ein paar Ideen:",
  dsschulung: "Datenschutzschulung",
  auswahl_loeschen: "Auswahl löschen",
  verarbeitung_dsfa: "Datenschutzfolgenabschätzung öffnen",
  rolle: "Rolle",
  kontaktiere_admin: "Bitten Sie den Admin Ihrer Instanz, Ihnen Zugriff zu erteilen.",
  frageBearbeiten: "Frage bearbeiten",
  pa_14: "Bewerten Sie die allgemeinen technischen und organisatorischen Maßnahmen gem. Art. 30 Abs. 1 lit. g) i.V.m. Art. 32 Abs. 1 DSGVO, um das Risiko der Verarbeitungstätigkeit zu minimieren.",
  umsatz_positiv: "Der Umsatz muss positiv sein.",
  mittleres_risiko: "Mittleres Risiko",
  andauernderVorfall: "Andauernder Vorfall",
  feststellungDesVorfalls: "Feststellung des Vorfalls",
  lizenz_ica: "In Ihrer Lizenz ist die Verwaltung von Intercompany Agreements nicht enthalten.",
  no_labels: "Keine Labels ausgewählt",
  resource_risikoquelle: "Ressource Risikoquelle",
  ansprechpartner: "Ansprechpartner",
  zugriff_verweigert: "Zugriff verweigert",
  beschreibung_kategorien_verarbeitungen: "Beschreibung der Kategorien von Verarbeitungen",
  keine_massnahmen_dsfa: "Keine Maßnahmen vorhanden.",
  uebersicht: "Übersicht",
  datenpannen: "Datenpannen",
  vorlage_verarbeitung: "Vorlage Verarbeitungstätigkeit",
  autocomplete_suche: "Eingabe zum Suchen ...",
  bloecke_entfernen: "Alle nachfolgenden Blöcke ebenfalls entfernen",
  aufgaben_label: "Aufgaben-Label",
  dashboard: "Dashboard",
  verknuepfte_aufgabe_anlegen: "Möchten Sie zu dieser Dokumentation eine verknüpfte Aufgaben erstellen?",
  profilsprache: "Profilsprache",
  receiver_type_SON: "Sonstige",
  massnahmen_nicht_umgesetzt: "Folgende ausgewählte Maßnahmen sind bisher noch nicht umgesetzt:",
  response_400_erasure_routine: "Die Löschfrist ist für die Erstellung einer Aufgabe zu kurz. Es muss mindestens die Einheit 'Tage' verwendet werden.",
  nettowertBerechnen: "Nettorisikowert Berechnen",
  geplant: "Geplant",
  nicht_bestaetigt: "Keine Bestätigung",
  wiederholungsende: "Wiederholungsende",
  kategorienBetroffenerPersonen: "Kategorien betroffener Personen",
  exporttypWaehlen: "Wähle eine Exportart aus",
  pa_7: "Wählen Sie die zutreffenden Kategorien der personenbezogenen Daten der Verarbeitungstätigkeit aus gem. Art. 30 Abs. 1 lit. c) DSGVO. Beachten Sie, dass die Auswahl der Kategorien einen Einfluss auf die Risikobewertung der Verarbeitung haben.",
  DA_single: "Tag",
  erfolgreich_umgesetzt: "Erfolgreich umgesetzt",
  vererbung: "Vererbung",
  meldendePerson: "Meldende Person",
  export_vorlagen: "Export-Vorlagen",
  erinnerungsfrist_fuer_Pruefungen: "Erinnerungsfrist für Prüfungen",
  name_der_checkliste: "Name der Checkliste",
  weiteresRisikosezenarioErfassen: "Weiteres Risikosezenario Erfassen",
  create_checklist_question_hint_multiselect: "Stellen Sie die Frage so, dass mehrere Antworten zutreffen können.",
  moeglicheSchaedenSlider2: "substanziell",
  ica_officer: "Zuständiger Benutzer für Intercompany Agreements",
  word_erstellen: "Word Vorlage erstellen",
  lizenz_benutzer_hinzufuegen: "In Ihrer Lizenz ist das Hinzufügen weiterer Benutzer nicht enthalten. Kontaktieren Sie uns unter",
  stammdaten: "Stammdaten",
  NeueVVTErstellen: "Möchten Sie die neue Vearbeitungstätigkeit anhand einer bestehenden Vorlage erstellen?",
  moeglicheSchaedenSlider0: "geringfügig",
  deutsch: "Deutsch",
  tage: "Tage",
  confirmation_intercompany_agreement: "Am {confirmed_at} bestätigt durch {forename} {surname}",
  benutzer_loeschen: "Benutzer entfernen",
  datenschutzniveau: "Datenschutzniveau",
  zuordnungRisikoabstufungen: "Zuordnung zu Risikoabstufungen",
  ausgewaehlt_elemente: "Ausgewählte Elemente",
  bearbeitung_vererbung_blockiert: "Die Vererbung wird derzeit im Hintergrund ausgeführt. Sobald der Vorgang abgeschlossen ist, erhalten Sie eine In-System-Benachrichtigung und können den Datensatz wieder bearbeiten.",
  getroffeneMaßnahmen: "Getroffene Maßnahmen",
  datenpanne_report_loeschen: "Datenpanne Benachrichtigung löschen",
  umgesetzte_maßnahmen: "Umgesetzte Maßnahmen",
  unternehmen_hinzufuegen: "Unternehmen hinzufügen",
  AnzahlDerAudits: "Anzahl der Audits",
  esWurdenNochKeineMaßnahmenZurAbmilderungDesRisikosGetroffen: "Es wurden noch keine Maßnahmen zur Abmilderung des Risikos getroffen",
  dateien: "Dateien",
  datenpanne: "Datenpanne",
  kategorie: "Kategorie",
  meldungZuSpaet: "Meldung erfolgt später als 72 Stunden nach Bekanntwerden",
  loeschfrist: "Löschfrist",
  keine_dateien: "Keine Dateien vorhanden",
  firma: "Firma",
  upload_text_multiple: "Dateien hierher verschieben oder hier klicken um Dateien auszuwählen.",
  tag: "Tag",
  jahr: "Jahr",
  generator: "Dokumentengenerator",
  machen_Sie_weiter: "Machen Sie weiter, wo Sie aufgehört haben",
  meldefrist: "Meldefrist",
  EsIstEineDatenschutzfolgenabschaetzungErforderlich: "Es ist eine Datenschutzfolgenabschätzung erforderlich.",
  nicht_speichern_wegen_standard: "Bei dieser Resource handelt es sich um eine Standardresource. Daher kann diese nicht bearbeitet und verändert werden.",
  verarbeitungs_wirklich_erstellen: "Möchten Sie aus diesen Informationen wirklich eine Verarbeitungstätigkeit erstellen?",
  zustaendig: "Zuständig",
  hinweis_vererbte_verarbeitung: "Sie bearbeiten eine vererbte Verarbeitung. Die Bearbeitungsfelder sind leer, damit Sie sehen, welche Inhalte Sie überschreiben. Die bereits vererbten Inhalte, sehen Sie auf der Detailansicht.",
  jahresumsatz: "Jahresumsatz in Euro",
  abmelden: "Abmelden",
  auswahlmoeglichkeiten: "Auswahlmöglichkeiten",
  abbrechen: "Abbrechen",
  tochtunternehmen_inkludieren: "Sollen alle Tochterunternehmen der ausgewählten Unternehmen ebenfalls mit eingeschlossen werden?",
  moeglicheSchaeden: "Mögliche Schäden",
  vvt: "Verzeichnis von Verarbeitungstätigkeiten",
  kommentare: "Kommentare",
  max_upload_multiple: "Hinweis: Alle ausgewählten Dateien dürfen zusammen maximal 20 MB groß sein.",
  themenkapitelBearbeiten: "Themenkapitel bearbeiten",
  vererbung_an: "{name} wird aktuell an folgende Unternehmen vererbt:",
  pa_2: "Wähle hier die zuständigen Personen aus, welche die Prozessverantwortlichkeit für diese Verarbeitung besitzen. Beachte, dass die erste Person auch automatisch im Löschkonzept für die Verarbeitung eingetragen wird.",
  unternehmen: "Unternehmen",
  kategorien_dermaßnahmen: "Kategorie(n) der Maßnahmen",
  tom_hinweis_im_auftrag: "Eine Übersicht der allgemeinen technischen und organisatorischen Maßnahmen finden Sie in der <a class='measures'>TOM-Übersicht</a>.",
  zwecke: "Zwecke",
  keineMoeglichenSchaeden: "Keine möglichen Schäden",
  datenempfaenger: "Datenempfänger",
  zentrale_benutzerverwaltung: "Zentrale Benutzerverwaltung",
  benutzer: "Benutzer",
  organisation_wechseln: "Organisation wechseln",
  resource_datenpanne_grund: "Ressource Grund Datenpanne",
  label: "Label",
  ansehen: "Ansehen",
  datumEnde: "Datum Ende",
  secure_countries: "Für die Länder {countries} besteht ein Angemessenheitsbeschluss.",
  interne_empfaenger: "Interne Empfänger",
  MO_single: "Monat",
  risikoszenarien: "Risikoszenarien",
  beteiligteEmpfaenger: "Beteiligte Empfänger",
  upfront_confirmed: "Wurde der Vertrag bereits von allen (zukünftig) hinterlegten Unternehmen unterzeichnet?",
  meine_aufgaben: "Meine Aufgaben",
  meldung_vom: "Meldung vom",
  besondere_personenbezogener_Daten: "Besondere personenbezogene Daten",
  einwilligungen: "Einwilligungen",
  anrede: "Anrede",
  jahresberichtErstellen: "Jahresbericht erstellen",
  create_checklist_question_hint_singleselect: "Stellen Sie die Frage so, dass nur eine Antwort einschlägig ist.",
  AnzahlDerTechUndOrgMaßnahmen: "Anzahl der technischen und organisatorischen Maßnahmen",
  kopieren: "Kopieren",
  vererbung_bestaetigung: "Soll {name} wirklich an {companies} {sub_companies} vererbt werden?",
  version: "Version",
  erlaeuterung: "Erläuterung",
  data_receiver_warning_country: "Dieses Land ist nicht Teil des europäischen Wirtschaftsraums.",
  allgemeines: "Allgemeines",
  du_kannst: "Du kannst",
  vererbungen_aufheben_text: "Wollen Sie die Vererbung wirklich aufheben?",
  ica_upload_upfront_confirmed: "Es werden alle bisherigen Bestätigungen der Unternehmen zurückgesetzt.",
  branche: "Branche",
  hinzufuegen_themenkapitel: "Fügen Sie ein Themenkapitel hinzu",
  datenpanne_report_bearbeiten: "Datenpanne Benachrichtigung bearbeiten",
  geschaeftsfuehrer: "Geschäftsführer",
  zeiterfassung: "Zeiterfassung",
  benutzer_erstellen: "Benutzer erstellen",
  vertrag_erstellen: "Vertrag erstellen",
  resource_aufbewahrung: "Ressource Aufbewahrungsort",
  begruendungVerspaeteteMeldung: "Begründung für die verspätete Meldung",
  softwareloesungen: "Softwarelösungen",
  vollstaendiges_konzept: "Vollständiges Konzept",
  email: "E-Mail",
  intercompany_agreement_delete_company: "Beteiligtes Unternehmen entfernen",
  organisationen: "Organisationen",
  nein: "Nein",
  vererbung_bestaetigen: "Vererbung bestätigen",
  risk_1: "Gering",
  externe_kommunikation: "Externe Kommunikation",
  land: "Land",
  http_413: "Upload fehlgeschlagen. Die Dateigröße von 20 MB wurde überschritten.",
  begründungFuerEntscheidung: "Begründung für die Entscheidung",
  meldung: "Meldung",
  ausblenden: "ausblenden",
  demo_aufgaben_nicht_verschoben: "In der Demo können die Aufgaben nicht verändert oder verschoben werden.",
  resource_ort: "Ressource Ort",
  WählenSieeinQuartalaus: "Wählen Sie ein Quartal aus",
  MeldungErfolgtSpaeter: "Erfolgt die Meldung später als 72 Stunden nach Bekanntwerden?",
  checklisten: "Checklisten",
  erstellt_von: "Erstellt von",
  inherited_receiver_included: "Sie können keine Verarbeitungstätigkeit vererben, in welcher ein bereits vererbter Datenempfänger verwendet wird.",
  erstelleMoeglicheSchaeden: "Erstellen Sie einen möglichen Schaden",
  loeschen: "Löschen",
  hinweis: "Hinweis",
  inhalte: "Inhalte",
  erstellungEinerCheckliste: "Erstellung einer Checkliste",
  englisch: "Englisch",
  hohes_risiko: "Hohes Risiko",
  risikoszenarioErstellen: "Risikoszenario erstellen",
  farbe: "Farbe",
  response_400_inheritance: "Dieses Objekt wurde bereits an das Zielunternehmen vererbt.",
  pa_10: "Wählen Sie die beteiligten internen Empfänger (Abteilungen) der Verarbeitungstätigkeit aus.",
  OeffneDiesesElementUmMöglicheHinweiseEinzusehen: "Öffnen Sie dieses Element, um mögliche Hinweise einzusehen",
  dokumentationen: "Dokumentationen",
  vererbung_erfolgreich: "Die Vererbung wird asynchron im Hintergrund ausgeführt. Dies kann je nach Umfang der Daten etwas Zeit in Anspruch nehmen. Sie erhalten eine Benachrichtigung, sobald die Aktion abgeschlossen wurde.",
  eingangsuhrzeit: "Eingangsuhrzeit",
  AnzahlDerDatenschutzfolgenabschaetzungen: "Anzahl der Datenschutzfolgenabschätzungen",
  werdenBetroffeneUeberDatenpanneBenachrichtigt: "Wurden oder werden die Betroffenen über die Datenpanne benachrichtigt?",
  error_deleted_user: "Ein gelöschter Benutzer darf nicht ausgewählt werden.",
  dsb: "Datenschutzbeauftragter",
  ica_upload: "Das Hochladen einer neuen Vertragsdatei überschreibt die aktuelle Datei unwiderruflich.",
  exportiert: "Der Export wird im Hintergrund erzeugt. Du erhältst eine Benachrichtigung, sobald der Download bereitsteht.",
  monat: "Monat",
  fragentyp: "Fragentyp",
  hochgeladeneDokumente: "Hochgeladene Dokumente",
  benachrichtigungBetroffenen: "Benachrichtigung der Betroffenen",
  schulungen: "Schulungen",
  vollstaendig_bestaetigt: "Vollständig bestätigt",
  ergaenzendeMitteilungenAusichtsbehoerde: "Ergänzende Mitteilungen an die Aufsichtsbehörde",
  auditsUndChecklisten: "Audits & Checklisten",
  risikoquelle: "Risikoquelle",
  verarbeitungsorte: "Verarbeitungsorte",
  meldungAufsichtsbehoerde: "Meldung an die Aufsichtsbehörde",
  vorlage_dokument: "Vorlage Dokument",
  response_400_processing_activity_impact_assessment: "Zu der ausgewählten Verarbeitungstätigkeit existiert bereits eine Datenschutzfolgenabschätzung.",
  einhundertZahl: "100",
  ReferenznummerAktenzeichen: "Referenznummer/Aktenzeichen",
  alle_Aufgaben_anzeigen: "Alle Aufgaben anzeigen",
  datumDerFeststellungDesVorfalls: "Datum der Festellung des Vorfalls",
  art: "Art",
  persoenlicheEinstellungen: "Persönliche Einstellungen",
  dsfa: "Datenschutzfolgenabschätzung",
  personenbezogene_Daten: "Personenbezogene Daten",
  einstellungen: "Einstellungen",
  umgesetzt: "Umgesetzt",
  spaltenAnzahl: "Spaltenanzahl",
  netto_risk_severity: "Nettorisikowert des Schadensausmaßes",
  audit_company: "Audit Unternehmen",
  diesen_kommentar: "diesen Kommentar",
  stunden: "Stunden",
  auditbericht_erklaerung: "Der Auditbericht stellt die Ergebnisse eines ausgewählten Audits zusammenfassend dar und dient als Grundlage zur Besprechung eines Audits.",
  dsfa_keine_informationen: "Es sind noch keine Informationen zur Verarbeitungstätigkeit vorhanden, bitte vervollständigen Sie diese.",
  farbeWaehlen: "Farbe wählen",
  WE_single: "Woche",
  grenzueberschreitendeUndAndereBenachrichtigungen: "Grenzüberschreitende und andere Benachrichtigungen",
  grund: "Grund",
  logoHochladen: "Logo hochladen",
  webseite: "Webseite",
  massnahmen_frage: "Maßnahmen-Frage",
  heute: "Heute",
  autor: "Autor",
  beschreibung: "Beschreibung",
  achtung_loeschen: "ACHTUNG - Sie entfernen den Nutzer aus allen Organisationen.",
  loeschen_2: "wirklich entfernen",
  informationen: "Informationen",
  auswertungen_und_berichte: "Berichte",
  benachrichtigung_vom: "Benachrichtigung vom",
  monate: "Monate",
  dskoordinator: "Datenschutzkoordinator",
  data_receiver_secure_country: "Dieses Land ist nicht Teil des europäischen Wirtschaftsraums, jedoch besteht aktuell ein Angemessenheitsbeschluss.",
  lizenz_vorlagen: "In Ihrer Lizenz ist das Erstellen von individuellen Exportvorlagen nicht enthalten. Kontaktieren Sie uns unter ",
  WollenSieWeiterhinBenachrichtigungenErhalten: "Wollen Sie weiterhin Benachrichtigungen erhalten?",
  suchen: "Suchen",
  RisikobewertungOhneMaßnahmen: "Risikobewertung ohne Maßnahmen",
  fortfolgende_themenkapitel: "Sie können nicht fortfolgende Themenkapitel erstellen, ohne eine Frage innerhalb des vorherigen Themenkapitels zu stellen.",
  audit_vorlage_erstellen: "Auditvorlage erstellen",
  statusgespraech: "Statusgespräch",
  keine_massnahmen: "Es wurden noch keine Maßnahmen ausgewählt.",
  loeschkonzept: "Löschkonzept",
  erfolgreich_speichern: "Erfolgreich gespeichert",
  beschreibung_routine: "An dieser Stelle können Sie eine Löschroutine erstellen. Beachte, dass für jede einzelne Löschfrist eine wiederkehrende Aufgabe erzeugt wird. Wählen Sie dazu die Personen aus, welche für die Löschroutine der Datenkategorie {category} in der Verarbeitungstätigkeit {activity} zuständig sind.",
  ausfuellhinweise: "Ausfüllhinweise",
  vererbt_von: "Vererbt von {company}",
  verlinkung: "Verlinkung",
  backlog: "Backlog",
  impact_assessment_recommendation: "Wir empfehlen Ihnen die Durchführung einer Datenschutzfolgendabschätzung für diese Verarbeitungstätigkeit.",
  datenpanne_info_ansehen: "Datenpanne Meldungen ansehen",
  pruefungen: "Prüfungen",
  dsfa_risiko_ergebnis: "Vor Maßnahmen ergibt sich {brutto}. Die Maßnahmen verringern das Risiko auf {netto}.",
  ip_beschraenkung: "Falls Ihr Unternehmen eine IP-Adress-Beschränkung eingerichtet hat, wenden Sie sich bitte an Ihren Administrator, um die Freischaltung zu veranlassen.",
  pruefungen_konfigurieren: "Prüfungen konfigurieren",
  word_vorlage: "Word Vorlage",
  benutzereinstellungen: "Benutzereinstellungen",
  rechtsgrundlage_der_Verarbeitungstätigkeit: "Rechtsgrundlage der Verarbeitungstätigkeit",
  datenpanneMeldepflichtig: "Ist die Datenpanne meldepflichtig?",
  konformitaet_name: "Konformität",
  risikoquellenHinzufuegen: "Risikoquellen Hinzufügen",
  vorlage: "Vorlage",
  hier: "hier",
  netto_risk_probability: "Nettorisikowert der Eintrittswahrscheinlichkeit",
  erstellungEinerTom: "Erstellung einer technischen und organisatorischen Maßnahme",
  dateien_hochladen: "Dateien hochladen",
  empfaenger: "Empfänger",
  risikoeinschaetzung: "Risikoeinschätzung",
  dauertDerVorfallNochAn: "Dauert der Vorfall noch an?",
  erstellungEinesAudit: "Erstellung eines Audit",
  einstellungen_fuer_Pruefungen: "Einstellungen für Prüfungen",
  gruppen: "Gruppen",
  name_der_checklistvorlage: "Name der Checklistvorlage",
  routine_erstellen: "Löschroutine erstellen",
  loeschroutine: "Löschroutine",
  risikoszenarien_bearbeiten: "Risikoszenarien bearbeiten",
  loeschroutine_erstellen: "Löschroutine erstellen",
  keine_daten: "Keine Daten vorhanden",
  tom_hinweis: "An dieser Stelle werden ausschließlich ausgewählte technische und organisatorische Maßnahmen dieser Verarbeitungstätigkeit genannt. Allgemeine technische und organisatorische Maßnahmen finden Sie in der <a class='measures'>TOM-Übersicht</a>.",
  response_400_storage_location: "Dieser Verarbeitungsort besteht für diesen Datenempfänger bereits.",
  export_benachrichtigung: "{name} herunterladen",
  upload_text: "Datei hierher verschieben oder hier klicken um Datei auszuwählen.",
  gewichtung: "Gewichtung",
  zustaendige_personen: "Zuständige Personen",
  periode_validierung: "Bei einer wiederkehrenden Aufgabe muss mindestens einer der Angaben größer 0 sein.",
  oeffnen: "Öffnen",
  keine_wiederkehrung: "Für diesen Datenempfänger sind bisher keine wiederkehrenden Prüfungen konfiguriert.",
  Info_P9_10_14_15_O9: "Diese Verarbeitung verwendet innovative Technologien. Andere Organisationen bewerten die Eintrittswahrscheinlichkeit als hoch und treffen Implementierungsmaßnahmen für Testsysteme und Testzyklen, sowie eine automatische Überwachung der Technologien zur Eindämung der Eintrittswahrscheinlichkeit.",
  kategorien: "Kategorie(n)",
  frist: "Frist",
  dritteBeteiligte: "Dritte Beteiligte",
  richtlinien_text: "Interne Richtlinien für den Umgang mit personenbezogenen Daten stellen sehr gute Orientierungen für Beschäftigte dar. Es werden Vorlagen für die Erarbeitung von Richtlinien zur Verfügung gestellt.",
  entfernen: "Entfernen",
  receiver_location_hint: "Der Sitz des Empfängers liegt im Land {country}. Dieses Land ist nicht Teil des europäischen Wirtschaftsraums und es besteht kein Angemessenheitsbeschluss.",
  pa_4: "Beschreiben Sie hier die Verarbeitungsvorgänge im Detail unter Berücksichtigung der Zwecke der Verarbeitungstätigkeit.",
  resource_betroffene: "Ressource Kategorie Betroffene",
  zutreffend: "Zutreffend",
  objekte: "Objekte",
  pdf_vorlagen: "PDF Vorlagen",
  zustaendig_personen: "Zuständige Personen",
  pa_8: "Wählen Sie die zutreffenden besonderen Kategorien der personenbezogenen Daten der Verarbeitungstätigkeit aus gem. Art. 30 Abs. 1 lit. c) DSGVO. Beachten Sie, dass die Auswahl der Kategorien einen Einfluss auf die Risikobewertung der Verarbeitung haben.",
  unternehmensstruktur: "Unternehmensstruktur",
  vorfallAnEinemBestimmtenZeitpunkt: "Hat sich der Vorfall an einem bestimmten Zeitpunkt oder in einem Zeitraum ereignet?",
  vorlage_dsfa: "Vorlage Datenschutzfolgenabschätzung",
  resource_status: "Ressource Status",
  hausnummer: "Hausnummer",
  alle_anzeigen: "Alle Anzeigen",
  question_hint_general: "Geben Sie zunächst den Fragetyp an, um hier weitere Informationen zur Beantwortung der Frage zu erhalten.",
  standard_template: "Standardvorlage",
  massnahmen_erstellen: "Alle Maßnahmen für das Unternehmen erstellen",
  passwortzuruecksetzen: "Passwort zurücksetzen",
  datenpanne_report_erstellen: "Datenpanne Benachrichtigung erstellen",
  filtern: "Filtern",
  checkbox_ica: "Hiermit bestätige ich, dass ich den Vertrag gelesen habe und alle Bestandteile akzeptiere.",
  erfassung_von_informationen_zur_relevanzpruefung: "Erfassung von Informationen zur Relevanzprüfung",
  status: "Status",
  erstellungEinerDSFA: "Erstellung einer DSFA",
  pdf_loeschen: "PDF Vorlage löschen",
  erstmeldung: "Erstmeldung",
  hinweis_tochterunternehmen_inkludiert: "Es werden nur die Tochterunternehmen eingeschlossen, auf welche Sie selbst Zugriff haben.",
  informationen_erstellen: "Informationen erstellen",
  Info_P6_7_8_12_O11: "Diese Verarbeitung besteht aus der Erfassung von Daten, welche zur Bewertung von Verhaltensweisen von natürlichen Personen verwendet werden können. Andere Organisationen bewerten das Schadensausmaß als hoch und treffen Maßnahmen zur Pseudonymisierung und Verschlüsselung der Datensätze.",
  aufsichtInformiert: "Wurde bzw. wird die zugehörige Aufsichtsbehörde informiert?",
  verarbeitungstaetigkeiten: "Verarbeitungstätigkeiten",
  benachrichtigungenEmail: "Benachrichtigungen per Email",
  ausgewaehlte_datei: "Ausgewählte Datei",
  zunaechst_tk_erstellen: "Bevor Sie eine Frage platzieren können, muss zunächst ein Themenkapitel erstellt werden.",
  benachrichtigung_meldung: "Meldung & Benachrichtigung",
  pa_12: "Ordnen Sie jeder Kategorie von personenbezogenen Daten die jeweiligen Löschfristen zu gem. Art. 30 Abs. 1 lit. f) DSGVO. Beachten Sie, dass diese Zuordnung vom DSMS als Grundlage für das Löschkonzept verwendet wird. Die Löschung der Datenkategorien gem. der Löschfristen muss erst nach Zweckentfall erfolgen.",
  intercompany_agreement: "Intercompany Agreement",
  resource_aufsicht: "Aufsichtsbehörde",
  sprache: "Sprache",
  variablenbezeichnung: "Variablenbezeichnung",
  kategorien_vererbung: "Die Kategorien {categories} wurden vererbt und können nicht entfernt oder bearbeitet werden.",
  betroffene: "Betroffene",
  vertraege_einholen: "Auftragsverarbeitungsverträge einholen",
  darstellung: "",
  audit: "Audit",
  zum_persoenlichen_dashboard: "Zum persönlichen Dashboard",
  vererbung_aufheben: "Vererbungen aufheben",
  textAbstandWaehlen: "Textabstand wählen",
  empfaenger_der_Pruefungen: "Empfänger der Prüfungen",
  artDerMeldung: "Art der Meldung",
  zustaendig_gruppen: "Zuständige Gruppen",
  informationen_erzeugt_durch_Verarbeitungstätigkeit_erasure: "Dieser Bereich zeigt Informationen an, welche durch die Bearbeitung von dem Verzeichnis von Verarbeitungstätigkeiten erzeugt werden. Die Löschung der Datenkategorien gem. der Löschfristen muss erst nach Zweckentfall erfolgen.",
  relevanzpruefung: "Relevanzprüfung",
  vorlagen: "Vorlagen",
  szenario: "Szenario",
  response_400_subcontractor: "Der Unterauftragnehmer ist für diesen Datenempfänger bereits hinterlegt.",
  netto_risk_scenario: "Nettorisikowert des Szenarios",
  bericht_erstellen: "Bericht erstellen",
  begruendungUndUrsacheDerRisikoquelle: "Begründung und Ursache der Risikoquelle",
  pa_16: "Bewerten Sie mit dem Risiko, wie sensibel das Datum ist. Umso sensibler das Datum umso höher das Risiko.",
  orte: "Orte",
  vererbung_erstellen: "Vererbung erstellen",
  datenschutzerklaerungen: "Datenschutzerklärungen ",
  freitext: "Freitext - Frage",
  keine_benachrichtigungen_vorhanden: "Keine Benachrichtigungen vorhanden",
  interne_Dokumente: "Interne Dokumente",
  kopfzeilen: "Kopfzeile",
  aufgabeBearbeiten: "Aufgabe bearbeiten",
  zuletzt_verwendet: "Zuletzt verwendet",
  hinzufuegen: "hinzufügen",
  measure_risk_4: "Sehr hohe Wirksamkeit",
  receiver_type_GMV: "Gemeinsam Verantwortlich",
  keine_vererbung: "{name} wurde bisher an kein anderes Unternehmen vererbt.",
  risk_3: "Hoch",
  aufsichtsbehoerde: "Aufsichtsbehörde",
  datumDerVorlaufigenMeldung: "Datum der vorläufigen Meldung",
  vertrag: "Vertrag",
  weitereFragen: "Weitere Risikofragen",
  keine_speicherorte: "Keine Orte der Verarbeitung hinterlegt.",
  data_receiver_subcontractor_loeschen: "Datenempfänger Unterauftragnehmer löschen",
  zur_verarbeitung: "Verarbeitungstätigkeit öffnen",
  nameDesRisikoszenarios: "Name des Risikoszenarios",
  word_loeschen: "Word Vorlage löschen",
  MI: "Minuten",
  rechtsgrundlagen: "Rechtsgrundlagen",
  schreibe_kommentar: "Schreibe einen Kommentar...",
  zustaendige_Person: "Zuständige Person",
  ende_am: "Ende am",
  support: "Support-Team",
  upload_word: "Word-Datei hierher verschieben oder hier klicken um Word-Datei auszuwählen.",
  versionsverlauf: "Versionsverlauf",
  fuegen_sie_eine_frage_hinzu: "Fügen Sie eine Frage hinzu",
  data_receiver_storage_erstellen: "Datenempfänger Verarbeitungsorte erstellen",
  Info_P5: "Diese Verarbeitung besteht aus einer systematischen umfangreichen Überwachung öffentlich zugänglicher Bereiche. Andere Organisationen bewerten daher die Eintrittswahrscheinlichkeit als hoch und treffen Maßnahmen gegen die unerlaubte Offenlegung dieser Daten, indem Berechtigungskonzepte und Löschkonzepte implementiert werden.",
  tom: "Technische und organisatorische Maßnahmen",
  frist_wiederkehrende_Pruefungen: "Frist wiederkehrende Prüfungen",
  nicht_umgesetzt: "Nicht umgesetzt",
  vertrag_herunterladen: "Vertrag herunterladen",
  pfad_referenz: "Dieser Pfad referenziert auf",
  YE_single: "Jahr",
  info: "Information",
  diese_vorlage: "diese Vorlage",
  jahresbericht_erklaerung: "Mit unserem Berichtsgenerator lassen sich Datenschutzaktivitäten und Veränderungen des vergangenen Zeitraums übersichtlich darstellen. Wichtige Kennzahlen liefern dabei wertvolle Einblicke für das Management.",
  gruppe: "Gruppe",
  bezeichnung: "Bezeichnung",
  resource_zweck: "Ressource Zweck",
  frage_tk: "Frage oder Themenkapitel",
  erstelleDritteBeteiligte: "Erstellen Sie einen dritten Beteiligten",
  bewertenSieDieAusgewaehltenToms: "Bewerten Sie die ausgewählten TOM(s)",
  betroffeneBesondereKategorienPersonenbezogenerDaten: "Betroffene Besondere Kategorien personenbezogener Daten",
  sicheres_drittland: "Sicheres Drittland",
  hinweis_benutzer_loeschen_plural: "Die zur Löschung ausgewählten Benutzer bleiben 30 Tage weiter bestehen, können jedoch nicht mehr ausgewählt werden und auf keine der Gesellschaften innerhalb der Organisationsstruktur zugreifen. Nach Ablauf der 30 Tage werden die Benutzer endgültig entfernt.",
  pdf_erstellen: "PDF Vorlage erstellen",
  anzahl_zu_klein: "Anzahl zu gering",
  vererbungen: "Vererbungen",
  funktion: "Funktion",
  zu_meinen_aufgaben: "Zu meinen Aufgaben",
  pa_18: "Beschreiben Sie an dieser Stelle die Kategorien von Verarbeitungen, welche im Auftrag durchgeführt werden.",
  audits: "Audits",
  no_audits: "Keine verknüpften Audits",
  AusgewaehlteRisikoquellen: "Ausgewählte Risikoquellen",
  wurdenMaßnahmenZurAbmilderungGetroffen: "Wurden bereits oder werden noch Maßnahmen zur Abmilderung des Risikos getroffen?",
  ja_nein_frage: "Ja/Nein - Frage",
  informationenZumVorfall: "Informationen zum Vorfall",
  name_der_Verarbeitungstätigkeit: "Name der Verarbeitungstätigkeit",
  zustaendige_abteilungen: "Zuständige Abteilungen",
  hintergrund: "Hintergrund",
  aendern: "Ändern",
  erstellungEinerChecklisteAuswahl: "Anhand welcher bestehenden Vorlage möchten Sie eine Checkliste erstellen?",
  unsicher_drittland: "Es erfolgt eine Datenübermittlung in ein Drittland.",
  schwereDesMoeglichenSchadens: "Schwere des möglichen Schadens",
  hinzu: "hinzu",
  datum: "Datum",
  aufgaben: "Aufgaben",
  verarbeitung_auftragsverarbeiter: "Verarbeitungen mit Auftragsverarbeitern",
  anzalDerBetroffenenPersonen: "Anzahl der betroffenen Personen",
  kein_drittland: "Es erfolgt keine Datenübermittlung in ein Drittland.",
  risikowert: "Risikowert",
  eingangsdatum: "Eingangsdatum",
  kein_zugriff: "Sie haben auf dieses Unternehmen keinen Zugriff.",
  blockierte_ressource: "Dieses Objekt wird aktuell bereits von {vorname} {nachname} bearbeitet.",
  task_1: "Konfigurieren Sie hier eine wiederkehrende Aufgabe. Weitere Informationen erhalten Sie in unserem FAQ.",
  is_vvt: "Verarbeitung im Auftrag?",
  geeignete_garantien: "Geeignete Garantien und Ausnahmen",
  NeueDSFAErstellen: "Möchten Sie die neue DSFA zu einer bestehenden Verarbeitungstätigkeit erstellen?",
  funktion_nicht_vorhanden: "Diese Funktion ist in der Demo nicht verfügbar.",
  pdf_ansehen: "PDF Vorlage ansehen",
  pa_11_kategorien_empfaenger: "Wählen Sie die beteiligten Kategorien der Empfänger der Verarbeitungstätigkeit aus.",
  alle_massnahmen_erstellen: "Möchten Sie wirklich alle Maßnahmen für das Unternehmen erstellen",
  woche: "Woche",
  assessmentRechtlicherHinweis: "Die Komplexität einer Datenpanne wird durch die Eingaben auf ein Minimum reduziert. Die Empfehlung basiert auf vereinfachten Annahmen und kann deshalb nicht immer zutreffend sein. Die Empfehlung kann eine von mehreren Indikatoren für eine Entscheidung darstellen. Eine Datenpanne sollte im Einzelfall immer detailliert betrachtet und bewertet werden.",
  hinweisVonDerDigitalenRechtsassistenz: "Hinweis von der digitalen Rechtsassistenz",
  vergangene_pruefungen: "Vergangene Prüfungen",
  pdf: "PDF",
  daten_init: "Daten vervollständigen",
  next_question: "Nächste Frage",
  risk_0: "Kein Risiko",
  vererbt_bekommen: "Vererbt bekommen?",
  resource_massnahme: "Ressource Maßnahme",
  berichte: "Berichte",
  brutto_risk_probability: "Bruttorisikowert der Eintrittswahrscheinlichkeit",
  bearbeitungshinweis: "Bearbeitungshinweis",
  EineRisikoanalyseIstNichtErforderlich: "Eine Risikoanalyse ist nicht erforderlich",
  fußzeilen: "Fußzeile",
  vertragsstatus: "Vertragsstatus",
  ergebnis: "Ergebnis",
  keine_benachrichtigungen: "Keine Benachrichtigungen",
  auftragsverarbeitung: "Auftragsverarbeitung",
  bis: "bis",
  ersatzbenutzer: "Ersatzbenutzer",
  keine_beschreibung: "Keine Beschreibung vorhanden.",
  verarbeitungstaetigkeit: "Verarbeitungstätigkeit",
  darstellung_sonstiges: "Darstellung und Sonstiges",
  keineDatenkategorienVorhanden: "Es wurden noch keine Datenkategorien ausgewählt. Wählen Sie unter Schritt 2 Datenkategorien aus, um anschließend Löschfristen zu erstellen.",
  auswahlToms: "Wählen Sie eine oder mehrere TOM(s) aus",
  in_bearbeitung: "In Bearbeitung",
  fehler_speichern: "Fehler beim Speichern",
  keine_Tags_vorhanden: "Keine Tags vorhanden",
  stammdaten_bearbeiten: "Stammdaten bearbeiten",
  beschreibung_der_Kenntnisse_des_Vorfalls: "Beschreibung der Kenntnisnahme des Vorfalls",
  verzeichnisVerabeitungenImAuftrag: "Verzeichnis Verarbeitungen im Auftrag",
  pfad: "Pfad",
  csv_hochladen: "CSV hochladen",
  organisationsuebergreifende_Daten: "Hier siehst du organisationsübergreifende Daten",
  abteilung: "Abteilung",
  orte_der_verarbeitung: "Orte der Verarbeitung",
  datei: "Datei",
  aufgaben_benachrichtigung: "Sie wurden der Aufgabe {name} zugewiesen",
  beschreibungGeplanteMaßnahmen: "Beschreibung der geplanten Maßnahmen",
  priority_2: "Hoch",
  name_des_audits: "Name des Audits",
  anzahl: "Anzahl",
  tabelle_anzeigen: "Tabelle anzeigen",
  response_400_ica_on_behalf: "Die ausgewählte Verarbeitung im Auftrag ist bereits mit einem anderen Intercompany Agreement verknüpft.",
  datenschutzerklaerungen_text: "Um die Informationspflichten aus Art. 12 ff. DSGVO zu erfüllen, können hier Schritt-für-Schritt Datenschutzerklärungen erstellt werden für verschiedene Zwecke.",
  Info_O12_14: "Es werden Datenempfänger eingesetzt, welche von einer Instanz eines Drittlands die Pflicht auferlegt bekommen können, diese Daten entgegen Art. 48 DSGVO zu exportieren oder offenzulegen. Andere Organisationen bewerten die Eintrittswahrscheinlichkeit als hoch und treffen Maßnahmen zur Verschlüsselung der Daten zum Schutz vor Zugriffen der Datenempfänger oder Instanzen aus den Drittländern.",
  problem_besteht: "Wenn das Problem weiterhin besteht, wenden Sie sich an unser",
  data_receiver_storage_loeschen: "Datenempfänger Verarbeitungsorte löschen",
  moeglicheSchaedenSlider1: "überschaubar",
  mehrfachauswahl: "Mehrfachauswahl - Frage",
  upfront_confirmed_info: "Wenn diese Option aktiviert wird, wird das Intercompany Agreement für alle bereits hinterlegten und zukünftig hinzugefügten Unternehmen automatisch bestätigt. Sie können diese Option jederzeit deaktivieren, sodass alle Unternehmen zu einer Bestätigung aufgefordert werden.",
  aufbewahrungsorte: "Aufbewahrungsorte",
  ort_der_Verarbeitung: "Ort der Verarbeitung",
  keine_meldungen_vorhanden: "Keine Meldungen vorhanden",
  sonstiges: "Sonstiges",
  nettorisikowert: "Nettorisikowert",
  bestimmtes_format: "Die Word-Export-Vorlage muss ein bestimmtes Format besitzen. Bitte lade",
  filter_loeschen: "Zurücksetzen",
  erstelleMeldung: "Erstellen Sie eine Meldung",
  DSFABeendenUndSpeichern: "DSFA beenden und speichern",
  komponente_waehlen: "Komponente auswählen",
  geplante_maßnahmen: "Geplante Maßnahmen",
  themenkapitel: "Themenkapitel",
  abteilungen: "Abteilungen",
  datenpanne_info_erstellen: "Datenpanne Meldungen erstellen",
  neuen_vertrag_hochladen: "Neuen Vertrag hochladen",
  anderes_konto_besitzen: "Wenn Sie ein anderes Konto besitzen, welches über weitere Berechtigungen verfügt, melden Sie sich mit diesem an.",
  welcomeToKeyed: "Willkommen bei Keyed",
  WählenSieEinAbgeschlossenesAuditAus: "Wählen Sie ein abgeschlossenes Audit aus",
  AnzahlDerAufgaben: "Anzahl der Aufgaben",
  verarbeitung_sicheres_drittland: "Verarbeitung in sicherem Drittland?",
  neuer_schaden: "Neuer Schaden",
  abteilung_unabhängig: "Abteilungsunabhängige Ansicht von Objekten",
  measure_risk_2: "Normale Wirksamkeit",
  pa_1: "Der Name der Verarbeitungstätigkeit dient gleichzeitig als Titel der Verarbeitung für alle Übersichten (z.B. Verzeichnis der Verarbeitungstätigkeiten, Löschkonzept) innerhalb des DSMS. Es ist zu empfehlen, dass der Name der Verarbeitung unabhängig von einzelnen IT-Tools sein sollte, die ggfs. im Rahmen der Verarbeitung zum Einsatz kommen.",
  details: "Details",
  StatusDerPruefungen: "Status der Prüfungen",
  pa_15: "Die Bewertung der Verarbeitungstätigkeit basiert auf der Auswertung aller Angaben in den vorherigen Schritten. Die Bewertung des Risikos kann bereits als Orientierung herangezogen werden, um zu bewerten, ob die Durchführung einer Datenschutz-Folgenabschätzung (DSFA) durchgeführt werden sollte. Beachten Sie, dass die Verarbeitungstätigkeit bereits in eine DSFA überführt werden kann, sodass eine effiziente Weiterverarbeitung gewährleistet wird.",
  neue_pruefung: "Neue Prüfung erstellen",
  plz: "Postleitzahl",
  Vertraege_datenempfaenger: "Verträge für Datenempfänger",
  benachrichtigung_erfolgt: "Benachrichtigung erfolgt?",
  datenuebertragung_in_drittland: "Datenübertragung in ein Drittland",
  naechster_baustein: "Nächster Baustein",
  potenzielleSchaeden: "Potenzielle Schäden",
  data_receiver_subcontractor_bearbeiten: "Datenempfänger Unterauftragnehmer bearbeiten",
  HO: "Stunden",
  uhrzeitDerFeststellungDesVorfalls: "Uhrzeit der Festellung des Vorfalls",
  themenkapitel_hinzufuegen: "Themenkapitel hinzufügen",
  ErgebnisNachMaßnahmen: "Ergebnis nach Maßnahmen",
  AnzahlDerBetroffenenanfragen: "Anzahl der Betroffenenanfragen",
  meldendePersonAuchAnsprechpartnerResult: "Die meldende Person ist auch Ansprechpartner",
  name_der_Datenpanne: "Name der Datenpanne",
  kein_risiko: "Kein Risiko",
  risk_chart_hint: "Das Risiko wird jeweils als Bruttorisiko (vor Maßnahmen) und als Nettorisiko (nach Maßnahmen) dargestellt. Das Bruttorisiko besteht aus dem Schadensausmaß und der Eintrittswahrscheinlichkeit einer Verletzung des Schutzes personenbezogener Daten. Die Berechnung des Schadensausmaßes wird aus den Angaben der Kategorien personenbezogener Daten, den Kategorien der Betroffenen und der Datenübertragung in ein Drittland vorgenommen. Zur Berechnung der Eintrittswahrscheinlichkeit werden die Angaben zur Datenübertragung in ein Drittland und der Löschfristen herangezogen. Alle Angaben, welche zur Auswahl stehen, enthalten Risikowerte und Gewichtungen für die Beeinflussung des Risikos. Das Bruttorisiko wird abschließend durch die ausgewählten technischen und organisatorischen Maßnahmen (TOM) minimiert und als Nettorisiko dargestellt.",
  erstelldatum: "Erstelldatum",
  no_documents: "Keine verknüpften Dokumente",
  docs_erlaubt: "Es sind nur Word-Dateien mit der Dateierweiterung .docx erlaubt.",
  Info_P2: "Bei dieser Verarbeitung sollen Daten, welche einer besonderen Vertraulichkeit unterliegen (z.B. Berufsgeheimnis), verarbeitet werden. Andere Organisationen bewerten daher das Schadensausmaß als hoch und treffen Maßnahmen zur Anonymisierung, Reduzierung und Verschlüsselung der Datensätze.",
  add: "hinzufügen",
  uhrzeitDerBenachrichtigung: "Uhrzeit der Benachrichtigung",
  download_edit: "herunter und bearbeite diese.",
  bitte_auswahl: "Bitte treffen Sie zunächst eine Auswahl",
  benachrichtigungspflicht: "Benachrichtigungspflicht",
  data_receiver_subcontractor_ansehen: "Datenempfänger Unterauftragnehmer ansehen",
  vererbt: "Vererbt?",
  keine_risikoquellen: "Es wurden noch keine Risikoquellen erstellt.",
  neu: "Neu",
  unten: "Unten",
  YE: "Jahre",
  personen: "Personen",
  geplanteMaßnahmen: "Geplante Maßnahmen",
  InformationenBearbeiten: "Informationen bearbeiten",
  auswahltext: "Auswahltext",
  priority_0: "Gering",
  dsfa_erstellen: "Datenschutzfolgenabschätzung erstellen",
  MO: "Monate",
  ort: "Ort",
  bearbeiten_klein: "bearbeiten",
  zurueck: "Zurück",
  erstellungEinerVearbeitungstaetigkeit: "Erstellung einer Vearbeitungstätigkeit",
  lizenz_unzureichend: "In Ihrer Lizenz ist diese Funktion nicht enthalten. Kontaktieren Sie uns unter",
  risikoquellen_waehlen: "Wähle eine oder mehrere Risikoquellen aus und begründe die Ursachen, sowie die potenziellen Schäden jeweils.",
  zwecke_der_Verarbeitung: "Zwecke der Verarbeitung",
  oben: "Oben",
  dokumente: "Dokumente",
  create_checklist_question_hint_freetext: "Stellen Sie die Frage so, dass die Antwort individuell formuliert werden kann.",
  weitereMitteilungenAnDatenschutzaufsichtsbehoerde: "Weitere Mitteilungen an Datenschutzaufsichtsbehörde",
  eintrittswahrscheinlichkeit: "Eintrittswahrscheinlichkeit",
  receiver_contracts_ica_append: " einsehen.",
  diese_stunden: "diese Stunden",
  herunterladen: "Herunterladen",
  name_der_auditvorlage: "Name der Auditvorlage",
  conformity_result: "Die Konformität des gesamten Audits beläuft sich auf",
  rollen: "Rollen",
  uhrzeitDermeldung: "Uhrzeit der Meldung",
  weiter: "Weiter",
  BearbeitungDerBenötigtenInformationen: "Bearbeitung der benötigten Informationen",
  Bitte_bestätige_zutreffende_einschlägige_Fragen_durch_die_Betätigung_der_Schaltfläche_und_füge_ggf_eine_Beschreibung_hinzu: "Bitte bestätigen Sie zutreffende einschlägige Fragen durch die Betätigung der Schaltfläche und fügen Sie ggf. eine Beschreibung hinzu.",
  beschreibungGetroffeneMaßnahmen: "Beschreibung der getroffenen Maßnahmen",
  pa_5: "Wählen Sie hier die einschlägigen Zwecke für die Verarbeitungstätigkeit gem. Art. 30. Abs. 1 lit. b) DSGVO aus.",
  verarbeitungsvorgaenge_beschreiben: "Beschreibe hier die Verarbeitungsvorgänge im Detail unter Berücksichtigung der Zwecke der Verarbeitungstätigkeit.",
  auditbericht: "Auditbericht",
  verknuepfte_Dokumentation: "Verknüpfte Dokumentation",
  erstellen_gross: "Erstellen",
  intercompany_agreement_add_company: "Beteiligtes Unternehmen hinzufügen",
  pa_13: "Treffen Sie spezifische technische und organisatorische Maßnahmen gem. Art. 30 Abs. 1 lit. g) i.V.m. Art. 32 Abs. 1 DSGVO, um das Risiko der Verarbeitungstätigkeit zu minimieren.",
  spaltenHoehe: "Spaltenhöhe",
  ZusammenfassungDerRisiken: "Zusammenfassung der Risiken",
  neue_aufgabe: "Neue Aufgabe",
  massnahme: "Maßnahme",
  ausmass_verletzung: "Das Schadensausmaß einer Verletzung des Schutzes personenbezogener Daten wird vornemlich beeinflusst durch die Auswahl der Kategorien personenbezogener Daten und der Kategorien Betroffener.",
  tochter_erstellen: "Tochterunternehmen erstellen",
  DA: "Tage",
  stundenanzahl: "Stundenanzahl",
  kleiner2: "< 2",
  resource_personal_data: "Ressource Kategorie personenbezogener Daten",
  hochladen: "Hochladen",
  vorlage_auswaehlen: "Vorlage auswählen",
  aufgabe: "Aufgabe",
  datenkategorie: "Datenkategorie",
  artDesVorfalls: "Art des Vorfalls",
  datumDerBenachrichtigung: "Datum der Benachrichtigung",
  geprueft: "Geprüft",
  uhrzeitBeginn: "Uhrzeit Beginn",
  ersteller: "Ersteller",
  falsch: "Falsch",
  datei_herunterladen: "Datei herunterladen",
  Vertraege_datenempfaenger_text: "Gerade bei der Datenübermittlung muss ein angemessenes Schutzniveau vertraglich zugesichert werden. Für die marktüblichen Konstellationen werden hier Vorlagen für diese Verträge zur Bearbeitung angeboten.",
  themenkapitel_frage_auswaehlen: "Themenkapitel oder Frage auswählen",
  erstellt_am: "Erstellt am",
  receiver_type_ICA: "Intercompany Agreement",
  NeueExterneKommunikationErstellen: "Möchten Sie eine Aufgabe erstellen, die sich auf die neu zu erstellende Externe Kommunikation bezieht?",
  erstellungEinesAuditAuswahl: "Anhand welcher bestehenden Vorlage möchten Sie ein Audit erstellen?",
  hinzufuegen_themenkapitel_oder_frage: "Fügen Sie ein Themenkapitel oder eine Frage hinzu",
  smartes_audit: "Soll die Vorlage für ein smartes Audit erstellt werden?",
  leistungsbeschreibung_aendern: "Ändere die Leistungsbeschreibung für den Subunternehmer",
  datei_hochladen: "Datei hochladen",
  type_communication: "Typen Externe Kommunikation",
  block_entfernen: "Möchtest du diesen Block wirklich entfernen?",
  anzalDerBetroffenenDatensaetze: "Anzahl der betroffenen Datensätze",
  risk_4: "Sehr Hoch",
  besondere_kategorien_personenbezogener_Daten: "Besondere Kategorie(n) personenbezogener Daten",
  antwort: "Antwort",
  risikoaudit: "Risikoaudit",
  konformitaetsaudit: "Konformitätsaudit",
  adressat: "Adressat",
  wert: "Wert",
  http_async_inherit: "Die Vererbung wird asynchron im Hintergrund ausgeführt. Abhängig vom Umfang der Daten kann dies einige Zeit in Anspruch nehmen. Sie erhalten eine Benachrichtigung, sobald die Aktion erfolgreich abgeschlossen ist.",
  http_async_process: "Die Bearbeitung wird asynchron im Hintergrund ausgeführt. Dies kann je nach Umfang der Daten etwas Zeit in Anspruch nehmen. Sie erhalten eine Benachrichtigung, sobald die Aktion abgeschlossen wurde.",
  http_async_destroy: "Die Löschung wird asynchron im Hintergrund ausgeführt. Dies kann je nach Umfang der Daten etwas Zeit in Anspruch nehmen. Sie erhalten eine Benachrichtigung, sobald die Aktion abgeschlossen wurde.",
  error_background_task_object_blocked: "Der Datensatz konnte nicht aktualisiert werden, da ein Hintergrundprozess noch nicht abgeschlossen ist.",
  error_background_task_object_blocked_destroy: "Der Datensatz konnte nicht gelöscht werden, da ein Hintergrundprozess noch nicht abgeschlossen ist.",
  done_background_task_inheritance: "Die Vererbung von {name} an {count} Unternehmen ist abgeschlossen",
  done_background_task: "Aktualisierung für {name} im Hintergrund abgeschlossen",
  http_async_process_revert_inherit: "Die Vererbung wird asynchron im Hintergrund aufgehoben. Abhängig vom Umfang der Daten kann dies einige Zeit in Anspruch nehmen. Sie erhalten eine Benachrichtigung, sobald die Aktion erfolgreich abgeschlossen ist.",
  leereAppAusgewaehlt: "Es wurde keine Art der Informationsausgabe festgelegt. Bitte wählen Sie mindestens die tabellarische Übersicht oder, bei Auswahl der Detailübersicht, einen entsprechenden Status. Folgende Bereiche sind betroffen: ",
  keineAppAusgewaehlt: "Es wurden keine Bereiche für die Informationsausgabe festgelegt. Bitte wählen Sie mindestens einen Bereich.",
  diesesJahr: "Dieses Jahr",
  letztesJahr: "Letztes Jahr",
  voreinstellungen: "Voreinstellungen",
  uebersicht_dsfa: "Übersicht DSFA",
  datum_waehlen: "Bitte wählen sie ein Datum",
  detail_ansicht_inkludieren_audit: "Detailübersicht (Auflistung) - aufgeteilt nach Art des Audits",
  detail_ansicht_inkludieren_deaktiviert: "Detailübersicht (Auflistung) ist für diesen Bereich nicht möglich",
  verzweigung: "Verzweigung",
  keineAntwort: "Keine Antwort",
  smartes_audit_speichern: "Die Bearbeitung muss vollständig abgeschlossen sein, bevor Sie das smarte Audit speichern können.",
  allgemein: "Allgemein",
  beginnDerAufzeichnung: "Beginn der Aufzeichnung",
  eingabe_kleiner_gleich: "Der Wert muss kleiner oder gleich {value} sein",
  fehler_export_bericht: "Der Export konnte nicht erzeugt werden",
  $vuetify: {
    ...de,
  },
};
