<template>
  <v-app>
    <v-navigation-drawer app v-model="drawer" width="300px" class="navbar" clipped v-if="company">
      <v-list nav dense class="d-flex flex-column justify-space-between fill-height">
        <div>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle></v-list-item-subtitle>
              <v-list-item-title>{{ company.name }}</v-list-item-title>
              <v-list-item-subtitle @click="openOrganisationDialog" style="cursor: pointer;">{{
                $t('organisation_wechseln')
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider class="mb-2 mt-2"></v-divider>

          <v-list-item link :to="{ name: 'companie-dashboard' }" exact-path color="primary">
            <v-list-item-icon>
              <v-icon>mdi-view-dashboard</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("dashboard") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group no-action>
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon>mdi-lan</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{
                  $t("organisation_verwalten")
                }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item link :to="{ name: 'company-structure' }" exact-path color="primary">
              <v-list-item-content>
                <v-list-item-title>{{
                  $t("unternehmensstruktur")
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link :to="{ name: 'company-data' }" exact-path color="primary">
              <v-list-item-content>
                <v-list-item-title>{{ $t("stammdaten") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link :to="{ name: 'company-departments' }" color="primary">
              <v-list-item-content>
                <v-list-item-title>{{ $t("abteilungen") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link :to="{ name: 'contact-management' }" color="primary">
              <v-list-item-content>
                <v-list-item-title>{{ $t("ansprechpartner") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link :to="{ name: 'company-settings' }" color="primary">
              <v-list-item-content>
                <v-list-item-title>{{ $t("einstellungen") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-item link :to="{ name: 'company-tasks' }" exact-path color="primary">
            <v-list-item-icon>
              <v-icon>mdi-list-status</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{
                $t("aufgabenuebersicht")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- @click="$router.push({ name: 'company-data-receiver' })" -->
          <v-list-group no-action>
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon>mdi-earth</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t("datenempfaenger") }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item link :to="{ name: 'company-data-receiver' }" color="primary">
              <v-list-item-content>
                <v-list-item-title>{{ $t("uebersicht") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link :to="{ name: 'subcontractors' }" color="primary">
              <v-list-item-content>
                <v-list-item-title>{{ $t("unterauftragnehmer") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link :to="{ name: 'intercompany_agreement' }" color="primary">
              <v-list-item-content>
                <v-list-item-title>{{ $t("intercompany_agreement") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-group no-action>
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon>mdi-file-document-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t("dokumentationen") }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item link :to="{ name: 'company-documentation-processing-activity' }" color="primary">
              <v-list-item-content>
                <v-list-item-title>{{ $t("vvt") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link :to="{ name: 'company-documentation-processing-on-behalf' }" color="primary">
              <v-list-item-content>
                <v-list-item-title>{{ $t("verabeitungenImAuftrag") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link :to="{ name: 'company-documentation-tom' }" exact-path color="primary">
              <v-list-item-content>
                <v-list-item-title>{{ $t("tom") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link :to="{ name: 'company-documentation-deletion' }" exact-path color="primary">
              <v-list-item-content>
                <v-list-item-title>{{ $t("loeschkonzept") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link :to="{ name: 'company-documentation-impact-assessment-list' }" color="primary">
              <v-list-item-content>
                <v-list-item-title>{{ $t("dsfa") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link :to="{ name: 'company-documentation-external-communication' }" color="primary">
              <v-list-item-content>
                <v-list-item-title>{{ $t("externe_kommunikation") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link :to="{ name: 'company-documentation-audits' }" color="primary">
              <v-list-item-content>
                <v-list-item-title>{{ $t("audits") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link :to="{ name: 'company-documentation-checklists' }" color="primary">
              <v-list-item-content>
                <v-list-item-title>{{ $t("checklisten") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link :to="{ name: 'company-documentation-data-breach' }" color="primary">
              <v-list-item-content>
                <v-list-item-title>{{ $t("datenpannen") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-item link :to="{ name: 'company-generator' }" color="primary">
            <v-list-item-icon>
              <v-icon>mdi-file-edit-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("generator") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link :to="{ name: 'company-repositories' }" exact-path color="primary">
            <v-list-item-icon>
              <v-icon>mdi-cloud-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("aufbewahrungsorte") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link :to="{ name: 'company-controller' }" color="primary">
            <v-list-item-icon>
              <v-icon>mdi-account-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("auftraggeber") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link :to="{ name: 'company-reports' }" exact-path color="primary">
            <v-list-item-icon>
              <v-icon>mdi-equalizer</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("berichte") }}</v-list-item-title>
            </v-list-item-content>

            <!-- Beta -->
            <v-list-item-action class="pa-0 ma-0">
              <v-chip color="primary" small label outlined>BETA</v-chip>
            </v-list-item-action>
          </v-list-item>


          <v-divider class="mb-2 mt-2"></v-divider>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>Global</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <!--<v-list-item link :to="{ name: 'global-reports' }" exact-path color="primary">
            <v-list-item-icon>
              <v-icon>mdi-equalizer</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("globale_berichte") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>-->

          <v-list-item link :to="{ name: 'company-resources' }" color="primary">
            <v-list-item-icon>
              <v-icon>mdi-database-sync-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("ressourcen") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link :to="{ name: 'company-templates' }" color="primary">
            <v-list-item-icon>
              <v-icon>mdi-lightbulb-on-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("vorlagen") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link :to="{ name: 'company-export-templates' }" color="primary">
            <v-list-item-icon>
              <v-icon>mdi-puzzle-edit-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("export_vorlagen") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link :to="{ name: 'central-user-management' }" color="primary"
            :disabled="!has_perm('company.central_user_management_access')">
            <v-list-item-icon>
              <v-icon>mdi-table-account</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("zentrale_benutzerverwaltung") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>

        <v-list-item>
          <v-list-item-content class="mt-auto">
            <v-list-item-title>
              <span class="text-caption text--secondary">Version 1.6</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app clipped-left elevation="2" :style="{ background: $vuetify.theme.themes.light.appbar_background }"
      class="header">
      <v-container fluid class="py-0 pl-0 fill-height">
        <v-app-bar-nav-icon @click="drawer = !drawer" :color="$vuetify.theme.themes.light.appbar_nav_icon"
          v-if="company">
        </v-app-bar-nav-icon>
        <v-img :src="logo_src" v-if="company" contain max-height="25px" max-width="120px" position="left center"
          class="ml-7" @click="goToCompanyDashboard()" style="cursor:pointer">
        </v-img>
        <v-img src="/files/static/frontend/keyed.svg" v-if="!company" contain max-height="25px" max-width="120px"
          position="left center" class="ml-7">
        </v-img>
        <v-spacer></v-spacer>
        <v-col cols="auto" class="ma-0 pa-0" v-if="!$vuetify.breakpoint.xs && company">
          <v-btn color="primary" class="mr-2" depressed small @click="createNewTask()"
            :disabled="!has_perm('task.add_task')">{{ $t("neue_aufgabe") }}
          </v-btn>
        </v-col>

        <v-btn icon href="https://help.keyed.de/" target="_blank"><v-icon>mdi-help-circle-outline</v-icon></v-btn>

        <!--
        <v-col cols="auto" class="mr-4 pa-0" v-if="!$vuetify.breakpoint.xs &&
          !$vuetify.breakpoint.sm &&
          company
          ">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-text-field dense flat hide-details filled rounded :placeholder="$t('suchen')" class="search-input"
                v-bind="attrs" v-on="on">
                <template v-slot:prepend-inner>
                  <v-icon>mdi-magnify</v-icon>
                </template>
              </v-text-field>
            </template>
            <span>{{ $t("funktion_nicht_vorhanden") }}</span>
          </v-tooltip>
        </v-col>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon :color="$vuetify.theme.themes.light.appbar_help_icon">mdi-help-circle-outline</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("funktion_nicht_vorhanden") }}</span>
        </v-tooltip>
        -->

        <v-menu bottom v-model="notificationMenu" min-width="320px" max-width="500px" rounded offset-y eager
          :close-on-content-click="false">
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click="new_notification = false">
              <v-badge color="primary" dot overlap v-if="new_notification">
                <v-icon>mdi-bell-outline</v-icon>
              </v-badge>
              <v-icon v-else>mdi-bell-outline</v-icon>
            </v-btn>
          </template>
          <NotificationCenter @notify="notify" @close="() => { notificationMenu = false; }"></NotificationCenter>
        </v-menu>

        <v-menu bottom min-width="220px" rounded offset-y>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-avatar color="primary" size="32" class="white--text">{{ get_avatar() }}</v-avatar>
            </v-btn>
          </template>
          <v-card>
            <v-list-item-content class="justify-center pb-0">
              <div class="mx-auto text-center">
                <v-avatar color="primary" size="32" class="white--text">{{ get_avatar() }}</v-avatar>
                <p class="font-weight-bold mt-3">{{ globalStore.user.forename }} {{ globalStore.user.surname }}</p>
                <p class="text-caption mt-1">{{ globalStore.user.email }}</p>

                <v-divider></v-divider>

                <v-list>

                  <v-list-item :to="{ name: 'my-tasks' }">
                    <v-list-item-content>
                      <v-list-item-title>{{
                        $t("meine_aufgaben")
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item :to="{ name: 'dashboard' }" exact-path v-if="$route.name != 'dashboard'">
                    <v-list-item-content>
                      <v-list-item-title>{{
                        $t("zum_persoenlichen_dashboard")
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item :to="{ name: 'personal-settings' }">
                    <v-list-item-content>
                      <v-list-item-title>{{
                        $t("einstellungen")
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-divider></v-divider>

                  <v-list-item @click="logout()">
                    <v-list-item-content>
                      <v-list-item-title>{{
                        $t("abmelden")
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </div>
            </v-list-item-content>
          </v-card>
        </v-menu>
        <TaskEditDialog ref="editTaskDialog" @save="saveNewTask" v-if="company"></TaskEditDialog>
      </v-container>
    </v-app-bar>

    <v-main :style="{ background: $vuetify.theme.themes.light.background }" :key="mainKey">
      <v-progress-linear color="primary" indeterminate v-if="globalStore.routeLoading"></v-progress-linear>
      <router-view />

      <ScrollToTopButton></ScrollToTopButton>
    </v-main>

    <SaveHint ref="saveHint"></SaveHint>

    <BasicDialog ref="companiesDialog" width="80vw" :eager="true">
      <TreeChart :data="company_structure" @open="closeCompaniesDialog" :create="false"></TreeChart>
    </BasicDialog>
    <NewsDialog ref="newsDialog"></NewsDialog>
  </v-app>
</template>

<script>
import { User, get_avatar } from './classes/User';
import { Company } from "@/classes/Company";
import SaveHint from '@/components/common/dialogs/SaveHint.vue';
import { mapStores } from 'pinia'
import { useGlobalStore } from '@/store/globalStore';
import { Task } from '@/classes/Task';
import NotificationCenter from '@/components/common/others/NotificationCenter.vue';
import ScrollToTopButton from '@/components/common/others/ScrollToTopButton.vue';
import { has_perm } from '@/classes/User';
import { downloadLogo } from '@/classes/CompanySettings';
import NewsDialog from "./components/news/dialog/NewsDialog.vue";

export default {
  name: "App",
  components: {
    TaskEditDialog: () => import("@/components/dialogs/TaskEditDialog.vue"),
    BasicDialog: () => import("@/components/common/dialogs/BasicDialog.vue"),
    TreeChart: () => import("@/components/charts/company-chart/TreeChart.vue"),
    SaveHint,
    NotificationCenter,
    ScrollToTopButton,
    NewsDialog,
  },
  data: () => ({
    drawer: false,
    //user: {},
    logo_src: "",
    company: false,
    new_notification: false,
    notificationMenu: false,
    company_structure: [],
    mainKey: 0,
  }),
  watch: {
    $route(to, from) {
      if (to.params?.companyId) {
        if (to.params.companyId !== from.params?.companyId) {
          Company.retrieve(to.params.companyId).then((res) => {
            this.company = new Company(res.data);
          });

          // Retrieve company structure
          Company.structure(true).then((res) => {
            this.company_structure = res.data;
          });
        }
      } else {
        this.company = false;
      }
    },
    // eslint-disable-next-line no-unused-vars
    '$vuetify.theme.logo': function (newVal, oldVal) {
      if (newVal != null) {
        downloadLogo(newVal).then(src => {
          this.logo_src = src;
        })
      } else {
        this.logo_src = "";
      }
    }
  },
  computed: {
    ...mapStores(useGlobalStore)
  },
  mounted() {
    User.list().then((res) => {
      //this.globalStore.setUser(res.data);

      // Set language
      this.$i18n.locale = res.data.language;
    });

    this.$root.$saveHint = this.$refs.saveHint;

    // Init navbar
    if (this.$vuetify.breakpoint.name == 'lg' || this.$vuetify.breakpoint.name == 'xl') {
      this.drawer = true;
    }

  },
  methods: {
    saveInitUser() {
      this.logout();
    },
    closeCompaniesDialog() {
      this.$refs.companiesDialog.close();
      this.mainKey += 1;
    },
    has_perm,
    openOrganisationDialog() {
      this.$refs.companiesDialog.open();
    },
    createNewTask() {
      this.$refs.editTaskDialog.open();
    },
    saveNewTask(task) {
      let result = Task.save(task);
      this.$root.$saveHint.open(result);
    },
    notify() {
      this.new_notification = true;
    },
    logout() {
      if (process.env.NODE_ENV === "production") {
        import("./plugins/keycloak").then((keycloak) => {
          keycloak.Keycloak.logout();
        });
      }
    },
    goToCompanyDashboard() {
      if (this.$route.name != 'companie-dashboard') {
        this.$router.push({ name: 'companie-dashboard' });
      }
    },
    get_avatar,
  }
};
</script>

<style>
.header {
  z-index: 10 !important;
}

.v-navigation-drawer--is-mobile {
  z-index: 11 !important;
}

.search-input .v-input__slot {
  background: var(--v-appbar_search_background-base) !important;
}
</style>